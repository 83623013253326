import { BobjetoPadrao } from "../../sistema/components/BobjetoPadrao";
import { Seller } from "../types/seller";

interface IProps {
  width?: string;
  name?: string;
  label?: string;
  placeholder?: string;
  includes?: string;
  onSelectItem?: (item: Seller) => void;
  onSelectItemOnLoad?: (item: Seller) => void;
}

export const SellerBobjeto = ({
  width = "100%",
  name = "seller_id",
  label = "Vendedor",
  placeholder = "",
  includes = "",
  onSelectItem,
  onSelectItemOnLoad,
}: IProps) => {
  const onSelect = (item: Seller) => {
    if (typeof onSelectItem === "function") {
      onSelectItem(item);
    }
    if (typeof onSelectItemOnLoad === "function") {
      onSelectItemOnLoad(item);
    }
  };

  return (
    <BobjetoPadrao
      onSelectItem={onSelect}
      onSelectItemOnLoad={onSelect}
      endpoint="sellers"
      placeholder={placeholder ? placeholder : label}
      label={label}
      name={name}
      includes={includes}
      visualizacao={(item: Seller) => (
        <>
          {item?.name} - {item?.active == 1 ? "Ativo" : "Inativo"}
        </>
      )}
      width={width}
    />
  );
};
