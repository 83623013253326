import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useIsAuth } from "../../hooks/useIsAuth"
import { useParams } from "react-router-dom";
import { useState, useEffect } from 'react'
import Layout from "../../sistema/components/Layout";
import { ListHeader } from "../../sistema/components/ListHeader";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import Loader from "../../sistema/components/Loader";
import InputField from "../../sistema/components/InputField";
import { Form, Formik } from "formik";
import { Box, Flex } from "@chakra-ui/react";
import validateForm from "../../utils/validateForm";
import InputSelect from "../../sistema/components/InputSelect";
import { ButtonHistorico } from "../../sistema/components/ButtonHistorico";
import { sistemaActions } from "../recucer";
import InputCheck from "../components/InputCheck";
import { Empresa } from "../types/empresa";
import { apiCall } from "../../utils/apiCall";
import InputFile from "../components/InputFile";
import InputNumber from "../components/InputNumber";

export const AssinaturasEdit = () => {
  useIsAuth();

  const { id } = useParams();

  const sistemaAssinatura = useSelector((state: RootState) => state.sistema.sistemaAssinatura)
  const isLoading = useSelector((state: RootState) => !!state.sistema.isLoading)
  const isMobile = useSelector((state: RootState) => !!state.sistema.isMobile)
  const [empresas, setEmpresas] = useState<Empresa[]>([]);

  const dispatch = useDispatch()

  useEffect(() => {
    if (!id) return;

    dispatch(sistemaActions.sistemaAssinaturaRequest(id))
  }, [dispatch, id])

  useEffect(() => {
    const getData = async () => {
      const { data } = await apiCall({
        url: `todas-empresas`, method: 'GET'
      });
      setEmpresas(data);
    }
    getData();
  }, [dispatch])

  const getArrayYears = () => {
    const anoAtual = (new Date()).getFullYear();

    const array = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

    return array.map((i) => ({
      label: anoAtual + i,
      value: anoAtual + i,
    }))
  }

  const getArrayMeses = () => {
    return [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro"
    ].map((i) => ({
      label: i,
      value: i,
    }))
  }

  const w_1_4 = isMobile ? '100%' : '25%';

  return (
    <Layout>
      <ListHeader label={'Assinatura'}>
        <DefaultButton ml={4} to={`/sistema-assinatura`}> Voltar </DefaultButton>
      </ListHeader>

      <Loader isLoading={isLoading} />

      {sistemaAssinatura && <Formik
        enableReinitialize
        initialValues={sistemaAssinatura}
        onSubmit={(val, { setErrors }) => {
          const validation = validateForm({ data_vencimento: 'required', competencia_mes: 'required', competencia_ano: 'required', empresa_destino_id: 'required' }, val)
          if (Object.keys(validation).length > 0) {
            setErrors(validation)
            return;
          }

          dispatch(sistemaActions.saveSistemaAssinaturaRequest({
            ...val,
            changed: true,
          }));
        }}
      >
        {({ values }) => (
          <Form>
            <Flex width="full" wrap="wrap">
              <InputField label="Data Vencimento" name="data_vencimento" type="date" width={w_1_4} />

              <InputSelect label="Mês Competência" name="competencia_mes" width={w_1_4}>
                {getArrayMeses().map((i) => (
                  <option value={i.value}>{i.label}</option>
                ))}
              </InputSelect>
              <InputSelect label="Ano Competência" name="competencia_ano" width={w_1_4}>
                {getArrayYears().map((i) => (
                  <option value={i.value}>{i.label}</option>
                ))}
              </InputSelect>
              <InputSelect label="Empresa" name="empresa_destino_id" width={w_1_4}>
                {empresas.map((i) => (
                  <option value={i.id}>{i.nome} ({i.cpf_cnpj})</option>
                ))}
              </InputSelect>

              <InputNumber name="valor" label="Valor" width={w_1_4} />

              <InputFile name="boleto" label="Boleto" />

              <InputCheck name="pago" label="Pago?" width={`50%`} />

              <Flex width={"full"} />
              <Box
                bg={"white"}
                mt={2}
                width={"full"}
                position="sticky"
                bottom="10px"
                zIndex="10"
                p={2}
              >
                <DefaultButton type="submit">Salvar assinatura</DefaultButton>
                {values.id && <ButtonHistorico par="sistema_assinatura" idpar={values.id} ml={2} />}
              </Box>
            </Flex>
          </Form>
        )}
      </Formik>}
    </Layout>
  )
}