import React, { useEffect, useState } from "react";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import { ButtonHistorico } from "../../sistema/components/ButtonHistorico";
import { Box, Flex } from "@chakra-ui/react";
import InputField from "../../sistema/components/InputField";
import InputSelect from "../../sistema/components/InputSelect";
import { novaPessoaContato } from "../data/pessoa_contato";
import { Filhos } from "../../sistema/components/Filhos";
import { CamposPersonalizadosForm } from "../../sistema/components/CamposPersonalizadosForm";
import InputNumber from "../../sistema/components/InputNumber";
import { BobjetoPadrao } from "../../sistema/components/BobjetoPadrao";
import { Cidade } from "../types/cidade";
import { Form, Formik, useField, useFormikContext } from "formik";
import Loader from "../../sistema/components/Loader";
import { Pessoa } from "../types/pessoa";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { retornaApenasNumero } from "../../utils/formatString";
import { apiCall } from "../../utils/apiCall";
import validateForm from "../../utils/validateForm";
import { cadastroActions } from "../reducer";
import { formatCelular } from "../../utils/formatCelular";
import { mensagemErro } from "../../utils/toasts";
import { SellerBobjeto } from "./SellerBobjeto";

type PessoaFormProps = {
  pessoa: Pessoa | null;
  tipo: string;
  isModal?: boolean;
};

export const PessoaForm = ({
  pessoa,
  tipo,
  isModal = false,
}: PessoaFormProps) => {
  const isLoading = useSelector(
    (state: RootState) => !!state.cadastro.isLoading
  );
  const isMobile = useSelector((state: RootState) => !!state.sistema.isMobile);

  const [isLoadingLocal, setIsLoadingLocal] = useState(false);

  const dispatch = useDispatch();

  interface IReceitaWs {
    nome: string;
    logradouro: string;
    numero: string;
    cep: string;
  }

  const [dadosCnpj, setDadosCnpj] = useState<IReceitaWs | null>(null);

  const consultaCnpj = async (cnpj: string) => {
    cnpj = retornaApenasNumero(cnpj);

    console.log(cnpj);

    if (cnpj.length < 14) return;

    try {
      setIsLoadingLocal(true);
      const { data } = await apiCall({
        url: `consulta-cnpj/${cnpj}`,
        method: "GET",
      });

      if (!data?.nome) {
        setIsLoadingLocal(false);
        return;
      }

      setDadosCnpj(data);
    } catch (err) {
      console.log(err);
      setIsLoadingLocal(false);
    }
  };

  const CnpjComponent = () => {
    const formik = useFormikContext();
    const [, , { setValue: setNome }] = useField("nome");
    const [, , { setValue: setEndereco }] = useField("endereco");
    const [, , { setValue: setNumero }] = useField("numero");
    const [, , { setValue: setCep }] = useField("cep");

    useEffect(() => {
      if (!dadosCnpj) {
        return;
      }

      setNome(dadosCnpj.nome);
      setEndereco(dadosCnpj.logradouro);
      setNumero(dadosCnpj.numero);
      setCep(retornaApenasNumero(dadosCnpj.cep));

      consultaCep(dadosCnpj.cep);

      setDadosCnpj(null);
    }, [formik, dadosCnpj]);

    return <></>;
  };

  interface DadosCep {
    bairro: string;
    complemento: string;
    ibge: string;
    localidade: string;
    uf: string;
    cidade_id: string;
  }

  const [dadosCep, setDadosCep] = useState<DadosCep | null>(null);

  const [consultingCep, setConsultingCep] = useState(false);

  const consultaCep = async (cep: string) => {
    cep = retornaApenasNumero(cep);

    if (cep.length < 8) {
      setIsLoadingLocal(false);
      return;
    }

    try {
      setConsultingCep(true);
      const { data } = await apiCall({
        url: `consulta-cep/${cep}`,
        method: "GET",
      });

      if (!data?.cidade_id) {
        setIsLoadingLocal(false);
        setConsultingCep(false);
        return;
      }

      setIsLoadingLocal(false);
      setConsultingCep(false);

      setDadosCep(data);
    } catch (err: any) {
      setIsLoadingLocal(false);
      setConsultingCep(false);
    }
  };

  const CepComponent = () => {
    const [, , { setValue: setValueCidadeId }] = useField("cidade_id");

    useEffect(() => {
      if (!dadosCep) {
        return;
      }

      setValueCidadeId(dadosCep.cidade_id);

      setDadosCep(null);
    }, [setValueCidadeId, dadosCep]);

    return <></>;
  };

  const w1_2 = isMobile ? "100%" : "33.33%";
  const w1_3 = isMobile ? "100%" : "33.33%";
  const w1_4 = isMobile ? "100%" : "33.33%";
  const w1_5 = isMobile ? "100%" : "33.33%";
  const w1_10 = isMobile ? "100%" : "33.33%";
  const numero_w = isMobile ? "100%" : "33.33%";

  const ePessoaJuridica = (values: Pessoa) => {
    return (values?.tipo_parceiro ?? "").trim() == "Pessoa jurídica";
  };

  return (
    <React.Fragment>
      <Loader isLoading={isLoading || isLoadingLocal} />

      {pessoa && (
        <Formik
          enableReinitialize
          initialValues={pessoa}
          onSubmit={(val, { setErrors }) => {
            const validation = validateForm(
              { nome: "required", tipo_parceiro: "required" },
              val
            );
            if (Object.keys(validation).length > 0) {
              mensagemErro(
                `Corrija os ${
                  Object.keys(validation).length
                } erros mostrados na tela e salve novamente.`
              );
              setErrors(validation);
              return;
            }

            dispatch(
              cadastroActions.savePessoaRequest({
                ...val,
                e_cliente: tipo == "clientes" ? 1 : 0,
                e_fornecedor: tipo != "clientes" ? 1 : 0,
                changed: true,
              })
            );
          }}
        >
          {({ values }) => (
            <Form>
              <Flex width="full" wrap="wrap">
                <InputSelect
                  label="Status"
                  name="status"
                  width={w1_10}
                  noPlaceholer={true}
                >
                  <option value="Ativo">Ativo</option>
                  <option value="Inativo">Inativo</option>
                </InputSelect>
                <InputSelect
                  label="Tipo Pessoa"
                  name="tipo_parceiro"
                  width={w1_10}
                  noPlaceholer={true}
                >
                  <option value="Pessoa física">Pessoa física</option>
                  <option value="Pessoa jurídica">Pessoa jurídica</option>
                </InputSelect>
                <InputField
                  onBlur={() => consultaCnpj(values.cpf_cnpj)}
                  mask={
                    ePessoaJuridica(values)
                      ? "99.999.999/9999-99"
                      : "999.999.999-99"
                  }
                  label={ePessoaJuridica(values) ? "CNPJ" : "CPF"}
                  width={w1_5}
                  name="cpf_cnpj"
                />
                <CnpjComponent />
                <InputField label="Nome" width={w1_2} name="nome" />
                <InputField
                  mask="99.999-999"
                  onBlur={() => consultaCep(values.cep)}
                  label={`CEP ${consultingCep ? "consultando..." : ""}`}
                  name="cep"
                  width={w1_5}
                />
                <CepComponent />
                <BobjetoPadrao
                  endpoint="cidades"
                  label="Cidade"
                  name="cidade_id"
                  visualizacao={(item: Cidade) => (
                    <>
                      {item?.nome} - {item?.uf}
                    </>
                  )}
                  width={w1_3}
                />
                <InputField label="Endereço" name="endereco" width={w1_3} />
                <InputField
                  label="Número"
                  name="numero"
                  width={numero_w}
                />
                <InputField
                  label="Complemento"
                  name="complemento"
                  width={w1_4}
                />
                <InputField label="E-mail" name="email" width={w1_5} />
                <InputNumber
                  label="Limite de crédito"
                  name="limite_credito"
                  width={w1_5}
                />
                {/* <InputSelectArray
                  label="Empresa Origem"
                  arrayName="empresas"
                  name="array_empresas"
                  width={w1_5}
                /> */}
                <InputField
                  type="date"
                  label="Data Integração"
                  name="data_integracao"
                  width={w1_5}
                />
                {tipo == "clientes" && (
                  <>
                    <InputField
                      type="date"
                      name="data_seproque"
                      label="Data do seproque"
                      width={w1_5}
                    />
                    <InputSelect
                      label="Seprocado?"
                      name="e_seprocado"
                      width={w1_5}
                      noPlaceholer={true}
                    >
                      <option value="0">Não</option>
                      <option value="1">Sim</option>
                    </InputSelect>
                    <SellerBobjeto width={w1_5} />
                  </>
                )}
                <CamposPersonalizadosForm
                  par={tipo == "clientes" ? "pessoa" : "pessoa-fornecedor"}
                  idp={pessoa?.id as number}
                  qtPorLinha={3}
                />
                <InputField
                  label="Observações"
                  name="observacoes"
                  width={"100%"}
                  textarea={true}
                  rows={3}
                />

                <div className="w-full my-1"></div>

                <Filhos
                  name="contatos"
                  tituloForm="Contatos"
                  editTituloForm="Contato"
                  novoItemObject={novaPessoaContato()}
                  validation={{ celular: "required", tipo: "required" }}
                  formatValuesBeforeSave={(values) => ({
                    ...values,
                    celular: retornaApenasNumero(values.celular),
                  })}
                  headers={[
                    {
                      label: "Tipo",
                      wrapped: false,
                      render: (item) => item.tipo,
                    },
                    {
                      label: "Nome",
                      wrapped: false,
                      render: (item) => item.nome,
                    },
                    {
                      label: "Celular",
                      wrapped: true,
                      render: (item) => formatCelular(item.celular),
                    },
                    {
                      label: "Observações",
                      wrapped: false,
                      render: (item) => item.observacoes,
                    },
                  ]}
                  form={(values) => (
                    <Flex width={"full"} wrap={"wrap"}>
                      <InputSelect
                        width={w1_3}
                        name="tipo"
                        label="Tipo de Contato"
                        noPlaceholer={true}
                      >
                        <option value="Principal">Principal</option>
                        <option value="Comprador">Comprador</option>
                        <option value="Financeiro">Financeiro</option>
                        <option value="Vendedor">Vendedor</option>
                      </InputSelect>
                      <InputField
                        name="nome"
                        label="Nome da Pessoa"
                        width={w1_3}
                      />
                      <InputField
                        mask="(99)99999-9999"
                        label="Celular"
                        name="celular"
                        width={w1_3}
                      />
                      <InputField
                        textarea
                        label="Observações"
                        name="observacoes"
                        rows={3}
                        width={"100%"}
                      />
                    </Flex>
                  )}
                />
                <Flex width={"full"} />
                <Box
                  bg={"white"}
                  mt={2}
                  width={"full"}
                  position="sticky"
                  bottom="10px"
                  zIndex="10"
                  p={2}
                >
                  <DefaultButton type="submit">Salvar cadastro</DefaultButton>
                  {values.id && (
                    <ButtonHistorico par="pessoa" idpar={values.id} ml={2} />
                  )}
                </Box>
              </Flex>
            </Form>
          )}
        </Formik>
      )}
    </React.Fragment>
  );
};
