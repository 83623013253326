import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Textarea,
  Text,
} from "@chakra-ui/react";
import { useField } from "formik";
import React, { InputHTMLAttributes, useEffect } from "react";
import ReactInputMask from "react-input-mask";
import { useFocus } from "../../hooks/useFocus";

type InputFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  name: string;
  textarea?: boolean;
  mask?: string;
  bgNone?: boolean;
  onChange?: () => void;
  rows?: number;
  noBorder?: boolean;
  noPadding?: boolean;
  onBlur?: () => void;
  autoComplete?: string;
  focus?: boolean;
};

const InputField: React.FC<InputFieldProps> = ({
  label,
  disabled,
  mask,
  textarea,
  bgNone = false,
  rows = 1,
  noBorder = false,
  width,
  noPadding = false,
  focus = false,
  onBlur,
  autoComplete,
  ...props
}) => {
  const [field, { error, touched }] = useField(props);

  const [inputRef, setInputFocus] = useFocus()

  useEffect(() => {
    if (focus) {
      (setInputFocus as any)();
    }
  }, [focus, setInputFocus])

  if (mask && mask.length > 1) {
    return (
      <FormControl width={width} isInvalid={touched && error ? true : false} p={noPadding ? 0 : 1}>
        {label && <FormLabel mb={0} mt={2}>{label}</FormLabel>}
        <Input
          bg={!bgNone ? 'white' : 'none'}
          color={!bgNone ? 'black': 'white'}
          as={ReactInputMask}
          mask={mask}
          disabled={disabled}
          type={props.type}
          {...field}
          isInvalid={touched && error ? true : false}
          borderRadius={noBorder ? 0 :2}
          placeholder={props.placeholder ? props.placeholder : label}
          onBlur={onBlur}
        />
        {error && <FormErrorMessage mt={1}>{error}</FormErrorMessage>}
      </FormControl>
    );
  }
  if (textarea) {
    return (
      <FormControl p={noPadding ? 0 : 1}>
        {label && <FormLabel mb={0} mt={2}>{label}</FormLabel>}
        <Textarea
          rows={rows}
          bg={!bgNone ? 'white' : 'none'}
          color={!bgNone ? 'black': 'white'}
          disabled={disabled}
          {...field}
          isInvalid={touched && error ? true : false}
          placeholder={props.placeholder ? props.placeholder : label}
          borderRadius={noBorder ? 0 :2}
        ></Textarea>
        {touched && error && <Text color="red">{error}</Text>}
      </FormControl>
    );
  }

  return (
    <FormControl className={props.className} width={width} isInvalid={touched && error ? true : false} p={noPadding ? 0 : 1}>
      {label && <FormLabel mt={2} mb={0}>{label}</FormLabel>}
      <Input
        autoComplete={autoComplete}
        bg={!bgNone ? 'white' : 'none'}
        color={!bgNone ? 'black': 'white'}
        disabled={disabled}
        type={props.type}
        placeholder={props.placeholder ? props.placeholder : label}
        {...field}
        isInvalid={touched && error ? true : false}
        borderRadius={noBorder ? 0 : 2}
        onBlur={onBlur}
        ref={inputRef}
      />
      {error && <FormErrorMessage mt={1}>{error}</FormErrorMessage>}
    </FormControl>
  );
};


export default InputField;