import { useDisclosure } from "@chakra-ui/react";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import { useState, useEffect } from "react";
import { Pessoa } from "../../cadastros/types/pessoa";
import { useDispatch, useSelector } from "react-redux";
import { apiCall } from "../../utils/apiCall";
import { RootState } from "../../app/mainReducer";
import { cadastroActions } from "../../cadastros/reducer";
import { mensagemErro, mensagemSucesso } from "../../utils/toasts";
import { formatError } from "../../utils/formatError";
import { ConfirmButton } from "../../sistema/components/ConfirmButton";
import { DialogModal } from "../../sistema/components/DialogModal";
import { ListHeader } from "../../sistema/components/ListHeader";
import { PessoaForm } from "../../cadastros/components/PessoaForm";
import { ListHeaderSecondary } from "../../sistema/components/ListHeaderSecondary";

export const DadosPessoaButton = ({
  pessoa_id,
  onDelete,
  onSavedCustomer,
}: {
  pessoa_id: number | string;
  onDelete?: () => void;
  onSavedCustomer?: (customer: Pessoa) => void;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const pessoa = useSelector((state: RootState) => state.cadastro.pessoa);
  const [isLoadingLocal, setIsLoadingLocal] = useState<boolean>(false);
  const sucessoCadastro = useSelector(
    (state: RootState) => state.cadastro?.success
  );

  const onGetData = () => {
    dispatch(cadastroActions.pessoaRequest(pessoa_id as string));
    onOpen();
  };

  const onDeleteCliente = async () => {
    try {
      setIsLoadingLocal(true);
      await apiCall({
        url: `pessoas/${pessoa_id}`,
        method: "DELETE",
      });
      setIsLoadingLocal(false);
      mensagemSucesso("Cliente apagado com sucesso!");
      typeof onDelete === "function" && onDelete();
      onClose();
    } catch (err: any) {
      mensagemErro(formatError(err));
      setIsLoadingLocal(false);
    }
  };

  useEffect(() => {
    if (sucessoCadastro == "Registro salvo com sucesso." && pessoa?.id) {
      onClose();
      typeof onSavedCustomer === "function" && onSavedCustomer(pessoa);
      dispatch(cadastroActions.success(""));
    }
  }, [sucessoCadastro, onClose, pessoa]);

  return (
    <>
      {pessoa_id && <DefaultButton
        onClick={() => {
          onOpen();
          onGetData();
        }}
        borderRadius={"2px"}
        m={"2px"}
        size={"sm"}
      >
        Dados cadastrais
      </DefaultButton>}

      <DialogModal
        show={isOpen}
        close={onClose}
        maxWidth="4xl"
        title={
          <ListHeaderSecondary>
            Dados cadastrais
            <DefaultButton ml={2} onClick={onClose}>
              Voltar
            </DefaultButton>
            {typeof onDelete === "function" && (
              <ConfirmButton
                colorSheme="red"
                isLoading={isLoadingLocal}
                size={"md"}
                confirm={onDeleteCliente}
                ml={2}
              >
                Apagar Cliente
              </ConfirmButton>
            )}
          </ListHeaderSecondary>
        }
        content={
          <div className="w-full">
            <div className="text-lg text-gray-700 my-4">
              {pessoa && (
                <PessoaForm
                  isModal={true}
                  pessoa={pessoa}
                  tipo={pessoa.e_cliente ? "clientes" : "fornecedores"}
                />
              )}
            </div>
          </div>
        }
        footer={
          <div className="w-full">
            <DefaultButton onClick={onClose}>Voltar</DefaultButton>
          </div>
        }
      />
    </>
  );
};
