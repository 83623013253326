import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useIsAuth } from "../../hooks/useIsAuth"
import { useParams } from "react-router-dom";
import { useEffect, useState } from 'react'
import Layout from "../../sistema/components/Layout";
import { ListHeader } from "../../sistema/components/ListHeader";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import Loader from "../../sistema/components/Loader";
import InputField from "../../sistema/components/InputField";
import { Form, Formik, useField } from "formik";
import { Box, Button, CircularProgress, Divider, Flex, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react";
import validateForm from "../../utils/validateForm";
import { ButtonHistorico } from "../../sistema/components/ButtonHistorico";
import { entradaesaidaActions } from "../recucer";
import InputNumber from "../../sistema/components/InputNumber";
import { CamposPersonalizadosForm } from "../../sistema/components/CamposPersonalizadosForm";
import { PessoaBobjeto } from "../../cadastros/components/PessoaBobjeto";
import { InputSelectArray } from "../../sistema/components/InputSelectArray";
import { Filhos } from "../../sistema/components/Filhos";
import { novaSacolaItem } from "../data/sacola_item";
import { formatNumber } from "../../utils/formatNumber";
import { ProdutoBobjeto } from "../components/ProdutoBobjeto";
import { useFocus } from "../../hooks/useFocus";
import { mensagemErro } from "../../utils/toasts";
import { apiCall } from "../../utils/apiCall";
import { SacolaItem } from "../types/sacola_item";
import { formatValor } from "../../utils/formatValor";
import { ListHeaderSecondary } from "../../sistema/components/ListHeaderSecondary";
import InputSelect from "../../sistema/components/InputSelect";
import { SellerBobjeto } from "../../cadastros/components/SellerBobjeto";

export const SacolasEdit = () => {
  useIsAuth();

  const { id } = useParams();

  const sacola = useSelector((state: RootState) => state.entradaesaida.sacola)
  const isLoading = useSelector((state: RootState) => !!state.entradaesaida.isLoading)
  const isMobile = useSelector((state: RootState) => !!state.sistema.isMobile)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!id) return;

    dispatch(entradaesaidaActions.sacolaRequest(id))
  }, [dispatch, id])

  const w_50 = isMobile ? '100%' : '50%';
  const w_25 = isMobile ? '100%' : '25%';

  const forceSubmitForm = () => {
    document.getElementById('botao-salvar-sacola')?.click();
  }

  const SacolaItens = () => {
    const [codBarras, setCodBarras] = useState('');
    const [isLoadingLocal, setIsLoadingLocal] = useState(false);
    const [focusQuantidade, setFocusQuantidade] = useState(false);
    const [inputRef, setInputFocus] = useFocus()

    const buscaProdutoPeloCodDeBarras = async () => {
      try {
        setIsLoadingLocal(true);
        const { data } = await apiCall({ url: `produto-pelo-cod-barras/${codBarras}`, method: 'GET' });

        setIsLoadingLocal(false);
        return data;
      } catch (err) {
        setIsLoadingLocal(false);
        console.log('err', err);
        return null;
      }
    }

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [, { value }, { setValue: setItens }] = useField('itens');

    const itensConst: SacolaItem[] = value;

    return (
      <>
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"2xl"}>
          <ModalOverlay />
          <ModalContent top={-10}>
            <ModalHeader>
              <ListHeaderSecondary label={'Devolução'} />
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody minH={'50vh'}>
              <Formik
                enableReinitialize
                initialValues={novaSacolaItem()}
                onSubmit={async (val, { setErrors }) => {
                  const isOk = validateForm({ produto_id: 'required', quantidade: 'min_number|0', }, val)
                  if (Object.keys(isOk).length > 0) {
                    setErrors(isOk)
                    mensagemErro("Corriga os erros e salve novamente.", 2000)
                    return;
                  }

                  const todosItens: SacolaItem[] = JSON.parse(JSON.stringify(itensConst));

                  const itemIndex = itensConst.findIndex((i) => i.id == val.id);

                  todosItens[itemIndex] = {
                    ...todosItens[itemIndex],
                    quantidade_devolvida: val.quantidade,
                    changed: true,
                  }

                  setItens(todosItens);
                  onClose();
                  forceSubmitForm();
                }}
              >
                {({ values, setFieldValue }) => (
                  <Form style={{
                    maxHeight: "calc(100vh - 220px)",
                    overflowY: 'auto'
                  }}>
                    <Flex width="full" wrap="wrap">

                      <Box width={"full"}>
                        {!values?.produto_id && <>
                          <FormControl width={'full'} p={1}>
                            <FormLabel mb={0} mt={2}>Buscar item por código de barras</FormLabel>
                            <Input
                              bg={'white'}
                              color={'black'}
                              placeholder={'Buscar item por código de barras'}
                              borderRadius={2}
                              value={codBarras}
                              ref={inputRef}
                              onChange={(e) => setCodBarras(e.target.value ?? '')}
                              onKeyDown={async (e) => {
                                if (e.key === 'Enter') {
                                  e.preventDefault();
                                  const itens = sacola?.itens ?? [];

                                  const produto = itens.find((i) => i.produto?.codigo_barras == codBarras);

                                  if (!produto) {
                                    mensagemErro("Produto não encontrado pelo código de barras. Selecione o produto pelo campo de produto.");
                                    setCodBarras('');
                                    return;
                                  }

                                  console.log('produto encontrado ', produto);

                                  setFieldValue('produto_id', produto.produto?.id);
                                  setFieldValue('quantidade', produto.quantidade);
                                  setFieldValue('id', produto.id);
                                  setCodBarras('');
                                  setFocusQuantidade(true);
                                }
                              }}
                            />
                          </FormControl>

                          <Button onClick={async () => {
                            const itens = sacola?.itens ?? [];

                            const produto = itens.find((i) => i.produto?.codigo_barras == codBarras);

                            if (!produto) {
                              mensagemErro("Produto não encontrado pelo código de barras. Selecione o produto pelo campo de produto.");
                              setCodBarras('');
                              return;
                            }

                            console.log('produto encontrado ', produto);

                            setFieldValue('produto_id', produto.produto?.id);
                            setFieldValue('quantidade', produto.quantidade);
                            setFieldValue('id', produto.id);
                            setCodBarras('');
                            setFocusQuantidade(true);
                          }} colorScheme="green" type="button" size={"sm"} width={'full'}>
                            {isLoadingLocal ? <CircularProgress isIndeterminate size={"14px"} /> : 'Buscar (Clique ou tecle enter)'}
                          </Button>

                          <Divider />
                        </>}


                        <Flex width={"full"} wrap={"wrap"}>
                          <InputSelect onChangeVal={(val) => {
                            const itemEncontrado = (sacola?.itens ?? []).find((i: any) => i.produto_id == val);

                            setFieldValue('quantidade', itemEncontrado?.quantidade ?? 0);
                            setFieldValue('id', itemEncontrado?.id);
                          }} name="produto_id" label="Produto" width={"full"}>
                            {sacola?.itens?.map((item: SacolaItem, index: number) => (
                              <option key={`${index}`} value={item.produto?.id}>
                                {item?.produto?.nome} - {formatValor(item?.produto?.preco ?? '0', 2)}
                              </option>
                            ))}
                          </InputSelect>
                          <InputNumber focus={focusQuantidade} name="quantidade" decimalPlaces={0} label="Quantidade" width={"full"} />
                        </Flex>
                      </Box>

                      <hr style={{ width: '100%' }} />

                      <DefaultButton type="submit" mt={4}>Devolver</DefaultButton>
                      <Button onClick={onClose} ml={2} mt={4} colorScheme="blackAlpha">Voltar</Button>
                    </Flex>
                  </Form>)}
              </Formik>
            </ModalBody>
          </ModalContent>
        </Modal>


        <Filhos
          name="itens"
          tituloForm="Itens da sacola"
          editTituloForm="Item"
          novoItemObject={novaSacolaItem()}
          validation={{
            produto_id: 'required',
            quantidade: 'min_number|0',
            preco: 'min_number|0',
          }}
          headers={[
            {
              label: "Produto",
              wrapped: false,
              render: (item) => item.produto?.nome,
            },
            {
              label: "Quantidade",
              wrapped: false,
              render: (item) => formatNumber(item.quantidade ?? '0', 2),
            },
            {
              label: "Preço Unitário",
              wrapped: false,
              render: (item) => formatNumber(item.preco ?? '0', 2),
            },
            {
              label: "Total",
              wrapped: false,
              render: (item) => formatNumber(item.quantidade * item.preco, 2),
            },
            {
              label: "Quantidade Devolvida",
              wrapped: false,
              render: (item) => formatNumber(item.quantidade_devolvida ?? '0', 2),
            },
          ]}
          formatValuesBeforeSave={async (values: SacolaItem) => {
            let produto = null;
            // try {
            //   const { data } = await apiCall({ url: `produtos/${values.produto_id}`, method: 'GET' });

            //   produto = data;
            // } catch (err) {
            //   console.log('err', err);
            // }

            return {
              ...values,
              produto,
            }
          }}
          canSave={(value) => {
            const itemExiste = (sacola?.itens ?? []).find((i) => i.id != value.id && i.produto_id == value.produto_id);

            if (itemExiste) {
              mensagemErro("Este produto já está nesta sacola! Apenas altere a quantidade.");
              return false;
            }

            return true;
          }}
          abriuModal={() => (setInputFocus as any)()}
          antesAbrirModal={() => { setFocusQuantidade(false) }}
          afterDelete={() => forceSubmitForm()}
          afterSave={() => forceSubmitForm()}
          extraButtonsOnTop={() => {
            return (
              <>
                {!!sacola?.id && <DefaultButton size="sm" ml={1} onClick={() => {
                  onOpen();
                  setTimeout(() => {
                    setFocusQuantidade(false);
                    (setInputFocus as any)();
                  }, 0)
                }}>Devolução</DefaultButton>}
              </>
            )
          }}
          form={(values, setFieldValue) =>
            <Box width={"full"}>
              {!values?.produto_id && <>
                <FormControl width={'full'} p={1}>
                  <FormLabel mb={0} mt={2}>Buscar item por código de barras</FormLabel>
                  <Input
                    bg={'white'}
                    color={'black'}
                    placeholder={'Buscar item por código de barras'}
                    borderRadius={2}
                    value={codBarras}
                    ref={inputRef}
                    onChange={(e) => setCodBarras(e.target.value ?? '')}
                    onKeyDown={async (e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        const produto = await buscaProdutoPeloCodDeBarras();

                        if (!produto) {
                          mensagemErro("Produto não encontrado pelo código de barras. Pesquise o produto pelo campo de produto.");
                          setCodBarras('');
                          return;
                        }

                        console.log('produto encontrado ', produto);

                        setFieldValue('produto_id', produto.id);
                        setCodBarras('');
                        setFocusQuantidade(true);
                      }
                    }}
                  />
                </FormControl>

                <Button onClick={async () => {
                  const produto = await buscaProdutoPeloCodDeBarras();

                  if (!produto) {
                    mensagemErro("Produto não encontrado pelo código de barras. Pesquise o produto pelo campo de produto.");
                    setCodBarras('');
                    return;
                  }

                  console.log('produto encontrado ', produto);

                  setFieldValue('produto_id', produto.id);
                  setCodBarras('');
                  setFocusQuantidade(true);
                }} colorScheme="green" type="button" size={"sm"} width={'full'}>
                  {isLoadingLocal ? <CircularProgress isIndeterminate size={"14px"} /> : 'Buscar (Clique ou tecle enter)'}
                </Button>

                <Divider />
              </>}

              <Flex width={"full"} wrap={"wrap"}>
                <ProdutoBobjeto onSelectItem={(produto) => {
                  if (produto) {
                    if ((produto as any).preco > 0) {
                      setFieldValue('preco', produto.preco)
                    }
                    return;
                  }
                  setFieldValue('preco', 0)
                }} width="full" />
                <InputNumber focus={focusQuantidade} name="quantidade" decimalPlaces={0} label="Quantidade" width={"full"} />
                <InputNumber name="preco" decimalPlaces={2} label="Preço" width={"full"} />
              </Flex>
            </Box>
          }
        />
      </>
    )
  }

  return (
    <Layout>
      <ListHeader label={'Sacola (Condicional)'}>
        <DefaultButton ml={4} to={`/sacolas`}> Voltar </DefaultButton>
      </ListHeader>

      <Loader isLoading={isLoading} />

      {sacola && <Formik
        enableReinitialize
        initialValues={sacola}
        onSubmit={(val, { setErrors }) => {
          const validation = validateForm({ pessoa_id: 'required', status: 'required' }, val)
          if (Object.keys(validation).length > 0) {
            setErrors(validation)
            return;
          }

          dispatch(entradaesaidaActions.saveSacolaRequest({
            ...val,
            changed: true,
          }));
        }}
      >
        {({ values }) => (
          <Form>
            <Flex width="full" wrap="wrap">
              <PessoaBobjeto name="pessoa_id" label="Cliente" width={w_50} />
                <InputField
                  type="date"
                  width={w_25}
                  label="Data limite devolução"
                  name="data_limite_devolucao"
                />
              <InputSelectArray
                width={w_25}
                name="status"
                label="Status"
                arrayName="sacola_status"
              />
              <SellerBobjeto width={"full"} />
              <InputField textarea rows={3} label="Observações" name="observacoes" width={"full"} />

              <CamposPersonalizadosForm par="sacola" idp={sacola?.id as number} />

              <Flex gap={2} width={"full"} wrap={"wrap"}>
                <Flex bg={"white"} rounded={"15px"} boxShadow={"base"} fontSize={"18px"} direction={"column"} p={4} alignItems={"center"} textAlign={"center"}>
                  <b style={{ marginBottom: '10px' }}> TOTAL GERAL </b>
                  R$ {formatValor(
                    values.itens.reduce((acumulador, atual: SacolaItem) => {
                      return acumulador += atual.quantidade * parseFloat(atual.preco);
                    }, 0)
                    , 2)}
                </Flex>
                <Flex bg={"white"} rounded={"15px"} boxShadow={"base"} fontSize={"18px"} direction={"column"} p={4} alignItems={"center"} textAlign={"center"}>
                  <b style={{ marginBottom: '10px' }}> DEVOLVIDO </b>
                  R$ {formatValor(
                    values.itens.reduce((acumulador, atual: SacolaItem) => {
                      return acumulador += atual.quantidade_devolvida * parseFloat(atual.preco);
                    }, 0)
                    , 2)}
                </Flex>
                <Flex bg={"white"} rounded={"15px"} boxShadow={"base"} fontSize={"18px"} direction={"column"} p={4} alignItems={"center"} textAlign={"center"}>
                  <b style={{ marginBottom: '10px' }}> TOTAL SEM DEVOLVER </b>
                  R$ {formatValor(
                    values.itens.reduce((acumulador, atual: SacolaItem) => {
                      return acumulador += atual.quantidade * parseFloat(atual.preco);
                    }, 0) - values.itens.reduce((acumulador, atual: SacolaItem) => {
                      return acumulador += atual.quantidade_devolvida * parseFloat(atual.preco);
                    }, 0)
                    , 2)}
                </Flex>

              </Flex>

              <SacolaItens />

              <Flex width={"full"} />
              <Box
                bg={"white"}
                mt={2}
                width={"full"}
                position="sticky"
                bottom="10px"
                zIndex="10"
                p={2}
              >
                <DefaultButton type="submit" id="botao-salvar-sacola">Salvar sacola</DefaultButton>
                {values.id && <ButtonHistorico par="sacola" idpar={values.id} ml={2} />}
              </Box>
            </Flex>
          </Form>
        )}
      </Formik>}
    </Layout>
  )
}