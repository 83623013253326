import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { ApiPaginationRes } from "../sistema/types/apiPaginationRes";
import { Produto } from "./types/produto";
import { mensagemErro, mensagemSucesso } from "../utils/toasts";
import { ListPayload } from "../sistema/types/listPayload";
import { Sacola } from "./types/sacola";
import { NotaPromissoria } from "./types/nota_promissoria";
import { StockLocation } from "./types/stock_location";
import { StockCurrent } from "./types/stock_current";
import { Stock } from "./types/stock";

export type ILista = {
  label: string;
  value: number;
}[];

type TInitialState = {
  error?: string;
  success?: string;
  isLoading?: boolean;
  produtos?: ApiPaginationRes<Produto[]>;
  produto?: Produto | null;
  sacolas?: ApiPaginationRes<Sacola[]>;
  sacola?: Sacola | null;
  notas_promissorias?: ApiPaginationRes<NotaPromissoria[]>;
  nota_promissoria?: NotaPromissoria | null;
  stocksLocation?: ApiPaginationRes<StockLocation[]>;
  stockLocation?: StockLocation | null;
  stocksCurrent?: StockCurrent[];
  stocksMovimentations?: ApiPaginationRes<Stock[]>;
};

const initialState: TInitialState = {};

const reducer = createSlice({
  name: "entradaesaida",
  initialState,
  reducers: {
    error(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = payload;
      state.success = "";
      mensagemErro(payload);
    },
    success(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = "";
      state.success = payload;
      if (payload) {
        mensagemSucesso(payload);
      }
    },
      
    stocksLocationRequest(state, _: PayloadAction<ListPayload>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    stocksLocationRequestSuccess(
      state,
      { payload }: PayloadAction<ApiPaginationRes<StockLocation[]>>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.stocksLocation = payload;
    },
    stockLocationRequest(state, _: PayloadAction<string>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
      state.stockLocation = null;
    },
    stockLocationRequestSuccess(state, { payload }: PayloadAction<StockLocation>) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.stockLocation = payload;
    },
    saveStockLocationRequest(state, _: PayloadAction<StockLocation>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    saveStockLocationRequestSuccess(state, { payload }: PayloadAction<StockLocation>) {
      state.isLoading = false;
      state.error = "";
      state.success = "Local de estoque salvo com sucesso.";
      state.stockLocation = payload;
      mensagemSucesso("Local de estoque salvo com sucesso.");
    },
    deleteStockLocationRequest(state, { payload }: PayloadAction<string | number>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    deleteStockLocationRequestSuccess(state) {
      state.isLoading = false;
      state.error = "";
      state.success = "Local de estoque apagado com sucesso.";
      mensagemSucesso("Local de estoque apagado com sucesso.");
    },

    produtosRequest(state, _: PayloadAction<ListPayload>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    produtosRequestSuccess(
      state,
      { payload }: PayloadAction<ApiPaginationRes<Produto[]>>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.produtos = payload;
    },
    produtoRequest(state, _: PayloadAction<string>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
      state.produto = null;
    },
    produtoRequestSuccess(state, { payload }: PayloadAction<Produto>) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.produto = payload;
    },
    saveProdutoRequest(state, _: PayloadAction<Produto>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    saveProdutoRequestSuccess(state, { payload }: PayloadAction<Produto>) {
      state.isLoading = false;
      state.error = "";
      state.success = "Produto salvo com sucesso.";
      state.produto = payload;
      mensagemSucesso("Produto salvo com sucesso.");
    },
    deleteProdutosRequest(state, { payload }: PayloadAction<string | number>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    deleteProdutosRequestSuccess(state) {
      state.isLoading = false;
      state.error = "";
      state.success = "Produto apagado com sucesso.";
      mensagemSucesso("Produto apagado com sucesso.");
    },

    sacolasRequest(state, _: PayloadAction<ListPayload>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    sacolasRequestSuccess(
      state,
      { payload }: PayloadAction<ApiPaginationRes<Sacola[]>>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.sacolas = payload;
    },
    sacolaRequest(state, _: PayloadAction<string>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
      state.sacola = null;
    },
    sacolaRequestSuccess(state, { payload }: PayloadAction<Sacola>) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.sacola = payload;
    },
    saveSacolaRequest(state, _: PayloadAction<Sacola>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    saveSacolaRequestSuccess(state, { payload }: PayloadAction<Sacola>) {
      state.isLoading = false;
      state.error = "";
      state.success = "Sacola salva com sucesso.";
      state.sacola = payload;
      mensagemSucesso("Sacola salva com sucesso.");
    },
    deleteSacolaRequest(state, { payload }: PayloadAction<string | number>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    deleteSacolaRequestSuccess(state) {
      state.isLoading = false;
      state.error = "";
      state.success = "Sacola apagada com sucesso.";
      mensagemSucesso("Sacola apagada com sucesso.");
    },

    notasPromissoriasRequest(state, _: PayloadAction<ListPayload>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    notasPromissoriasRequestSuccess(
      state,
      { payload }: PayloadAction<ApiPaginationRes<NotaPromissoria[]>>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.notas_promissorias = payload;
    },
    notaPromissoriaRequest(state, _: PayloadAction<string>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
      // state.nota_promissoria = null;
    },
    notaPromissoriaRequestSuccess(state, { payload }: PayloadAction<NotaPromissoria>) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.nota_promissoria = payload;
    },
    saveNotaPromissoriaRequest(state, _: PayloadAction<NotaPromissoria>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    saveNotaPromissoriaRequestSuccess(state, { payload }: PayloadAction<NotaPromissoria>) {
      state.isLoading = false;
      state.error = "";
      state.success = "Nota Promissória salva com sucesso.";
      state.nota_promissoria = payload;
      mensagemSucesso("Nota Promissória salva com sucesso.");
    },
    deleteNotaPromissoriaRequest(state, { payload }: PayloadAction<string | number>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    deleteNotaPromissoriaRequestSuccess(state) {
      state.isLoading = false;
      state.error = "";
      state.success = "Nota Promissória apagada com sucesso.";
      mensagemSucesso("Nota Promissória apagada com sucesso.");
    },

    stocksCurrentRequest(state, _: PayloadAction<string | number | null>) {
      state.isLoading = true;
    },
    stocksCurrentRequestSuccess(state, { payload }: PayloadAction<StockCurrent[]>) {
      state.isLoading = false;
      state.stocksCurrent = payload;
    },

    stocksMovimentationsRequest(state, _: PayloadAction<ListPayload>) {
      state.isLoading = true;
    },
    stocksMovimentationsRequestSuccess(state, { payload }: PayloadAction<ApiPaginationRes<Stock[]>>) {
      state.isLoading = false;
      state.stocksMovimentations = payload;
    },
    stocksMovimentationCancelRequest(state, _: PayloadAction<number>) {
      state.isLoading = true;
      state.success = "";
      state.error = "";
    },
    saveStockMovimentationRequest(state, _: PayloadAction<Stock>) {
      state.isLoading = true;
      state.success = "";
      state.error = "";
    },
    stockRecalculate(state, _: PayloadAction<number | string>) {
      state.isLoading = true;
      state.success = "";
      state.error = "";
    },
  },
});

export const entradaesaidaActions = reducer.actions;

export default reducer;
