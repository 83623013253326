import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { mensagemErro, mensagemSucesso } from "../utils/toasts";
import { Financeiro } from "./types/financeiro";
import { ApiPaginationRes } from "../sistema/types/apiPaginationRes";
import { IFiltrosRelatorioFinanceiro } from "./views/FinanceiroList";
import { FinanceiroParcela } from "./types/financeiro_parcela";

type TInitialState = {
  error?: string;
  success?: string;
  isLoading?: boolean;
  financeiro?: Financeiro | null;
  relatorioFinanceiro?: ApiPaginationRes<FinanceiroParcela[]>;
};

const initialState: TInitialState = {};

const reducer = createSlice({
  name: "financeiro",
  initialState,
  reducers: {
    error(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = payload;
      state.success = "";
      mensagemErro(payload);
    },
    success(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = "";
      state.success = payload;
      if (payload) {
        mensagemSucesso(payload);
      }
    },
    financeiroRequest(
      state,
      _: PayloadAction<{ id: string; tipo: "pagar" | "receber" }>
    ) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
      state.financeiro = null;
    },
    financeiroRequestSuccess(state, { payload }: PayloadAction<Financeiro>) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.financeiro = payload;
    },
    saveFinanceiroRequest(state, _: PayloadAction<Financeiro>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    saveFinanceiroRequestSuccess(
      state,
      { payload }: PayloadAction<Financeiro>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "Registro salvo com sucesso.";
      state.financeiro = payload;
      mensagemSucesso("Registro salvo com sucesso.");
    },
    deleteFinanceiroRequest(state, _: PayloadAction<string | number>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    deleteFinanceiroRequestSuccess(state) {
      state.isLoading = false;
      state.error = "";
      state.success = "Registro apagado com sucesso.";
      mensagemSucesso("Registro apagado com sucesso.");
    },

    buscaRelatorioFinanceiroRequest(
      state,
      _: PayloadAction<IFiltrosRelatorioFinanceiro>
    ) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    buscaRelatorioFinanceiroRequestSuccess(
      state,
      { payload }: PayloadAction<ApiPaginationRes<FinanceiroParcela[]>>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.relatorioFinanceiro = {
        ...payload,
        data: payload.data.map((i) => ({
          ...i,
          _valor_baixa: (parseFloat(i.valor) -
            parseFloat(i.valor_quitado)) as any,
        })),
      };
    },
  },
});

export const financeiroActions = reducer.actions;

export default reducer;
