import React, { useState } from "react";
import { DialogModal } from "./DialogModal";
import { Button, Link, useDisclosure } from "@chakra-ui/react";
import { DefaultButton } from "./DefaultButton";
import { ListHeader } from "./ListHeader";
import { CampoPersonalizado } from "../types/campo_personalizado";
import { apiCall } from "../../utils/apiCall";
import Loader from "./Loader";
import { CampoPersonalizadoForm } from "./CampoPersonalizadoForm";
import { FiEdit, FiTrash } from "react-icons/fi";
import ResponsiveTable, { TableHeaders } from "./ResponsiveTable";
import { getFieldsTypes } from "../arrays/camposPersonalizados";
import { mensagemErro } from "../../utils/toasts";
import { formatError } from "../../utils/formatError";
import { ConfirmButton } from "./ConfirmButton";
import { PessoaCampoPersonalizado } from "../types/pessoa_campo_personalizado";

type CamposPersonalizadosGerenciarProps = {
  retiraOpcaoAdicionarMais: boolean;
  par: string;
  idp: string;
  valuesInForm: PessoaCampoPersonalizado[];
  setNewValuesInForm: (values: PessoaCampoPersonalizado[]) => void;
};

export const CamposPersonalizadosGerenciar = ({
  retiraOpcaoAdicionarMais,
  par,
  idp,
  valuesInForm,
  setNewValuesInForm,
}: CamposPersonalizadosGerenciarProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [fields, setFields] = useState<PessoaCampoPersonalizado[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const onOpenModalGerenciarCampos = async () => {
    try {
      setIsLoading(true);
      const { data } = await apiCall({
        url: `campos-personalizados-todos/${par}`,
        method: "GET",
      });

      setFields(data);
      setIsLoading(false);
      onOpen();
    } catch (err: any) {
      console.log(err.response.data);
      setIsLoading(false);
    }
  };

  const [currentItem, setCurrentItem] = useState<CampoPersonalizado | null>(
    null
  );
  const {
    isOpen: isOpenField,
    onOpen: onOpenField,
    onClose: onCloseField,
  } = useDisclosure();

  const onDeleteItem = async (item: PessoaCampoPersonalizado) => {
    try {
      setIsLoading(true);

      await apiCall({
        url: `campos-personalizados/${item.campo_personalizado_id}`,
        method: "DELETE",
      });

      onOpenModalGerenciarCampos();

      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      console.log(formatError(err));
      mensagemErro(
        "Campo já foi preenchido em algum cadastro, só é possível inativá-lo."
      );
    }
  };

  const onOpenModal = async (id: string | number) => {
    setIsLoading(true);
    const { data } = await apiCall({
      url: `campos-personalizados/${id}`,
      method: "GET",
    });
    setCurrentItem(data);
    setIsLoading(false);
    onOpenField();
  };

  const headers: TableHeaders<PessoaCampoPersonalizado>[] = [
    {
      label: "Nome",
      wrapped: false,
      render: (item) => item.nome,
    },
    {
      label: "Tipo",
      wrapped: true,
      render: (item) =>
        getFieldsTypes().find((type) => type.value == item.tipo)?.name ?? "",
    },
    {
      label: "Ativo?",
      wrapped: true,
      render: (item) => (item.ativo == 1 ? "Sim" : "Não"),
    },
    {
      label: "Ações",
      wrapped: true,
      render: (item, index) => (
        <>
          {
            <DefaultButton
              size={"xs"}
              onClick={() => onOpenModal(item.campo_personalizado_id as number)}
            >
              <FiEdit />
            </DefaultButton>
          }

          <ConfirmButton size={"xs"} ml={1} confirm={() => onDeleteItem(item)}>
            <FiTrash />
          </ConfirmButton>
        </>
      ),
      notClicable: true,
    },
  ];

  return (
    <React.Fragment>
      {!isOpen && <Loader isLoading={isLoading} />}

      {!retiraOpcaoAdicionarMais && (
        <Link onClick={onOpenModalGerenciarCampos} px={1}>
          Gerenciar campos
        </Link>
      )}

      <CampoPersonalizadoForm
        isOpen={isOpenField}
        onClose={onCloseField}
        par={par}
        idp={idp as string}
        currentItem={currentItem}
        savedForm={({ current, fields, allFields }) => {
          setFields(allFields);

          const campoEncontradoIndex = (
            valuesInForm ?? ([] as PessoaCampoPersonalizado[])
          ).findIndex(
            (i: PessoaCampoPersonalizado) =>
              i.campo_personalizado_id == current.id
          );

          const campo = (fields as any).find(
            (i: PessoaCampoPersonalizado) =>
              i.campo_personalizado_id == (current as any).id
          );

          const itens = JSON.parse(JSON.stringify(valuesInForm));

          if (campoEncontradoIndex < 0) {
            itens.push(campo);
          } else {
            itens[campoEncontradoIndex] = campo;
          }
          setNewValuesInForm(itens);
        }}
      />

      <DialogModal
        show={isOpen}
        close={onClose}
        title={
          <ListHeader>
            Gerenciar campos{" "}
            <DefaultButton onClick={onClose}>Voltar</DefaultButton>
          </ListHeader>
        }
        content={
          <div className="w-full flex flex-wrap">
            <Loader isLoading={isLoading} />

            {fields && (
              <ResponsiveTable isSmall headers={headers} data={fields} />
            )}
          </div>
        }
        footer={
          <div className="w-full">
            <DefaultButton onClick={onClose}>Voltar</DefaultButton>
          </div>
        }
      />
    </React.Fragment>
  );
};
