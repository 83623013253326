import { FinanceiroParcela } from "../types/financeiro_parcela";

export const novoFinanceiroParcela = (): FinanceiroParcela => {
  return {
    codigo: "",
    data_vencimento: "",
    valor_original: "0",
    valor: "0",
    financeiro_id: "",
    status: 0,
    valor_quitado: "0",
    valor_troco: "0",
    quitacoes: [],
    comission_percentage: 0,
    comission_value: 0,
  };
};
