import { CampoPersonalizado } from "../types/campo_personalizado";

export const novoCampoPersonalizado = (): CampoPersonalizado => {
  return {
    nome: "",
    slug: "",
    tipo: "string",
    array: [],
    par: "",
    ativo: 1,
  };
};
