import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useIsAuth } from "../../hooks/useIsAuth"
import { useParams } from "react-router-dom";
import { useEffect } from 'react'
import Layout from "../../sistema/components/Layout";
import { ListHeader } from "../../sistema/components/ListHeader";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import Loader from "../../sistema/components/Loader";
import InputField from "../../sistema/components/InputField";
import { Form, Formik } from "formik";
import { Box, Flex } from "@chakra-ui/react";
import validateForm from "../../utils/validateForm";
import { ButtonHistorico } from "../../sistema/components/ButtonHistorico";
import { sistemaActions } from "../recucer";

export const EmpresaEdit = () => {
  useIsAuth();

  const { id } = useParams();

  const empresa = useSelector((state: RootState) => state.sistema.empresa)
  const isLoading = useSelector((state: RootState) => !!state.sistema.isLoading)
  const isMobile = useSelector((state: RootState) => !!state.sistema.isMobile)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!id) return;

    dispatch(sistemaActions.empresaRequest(id))
  }, [dispatch, id])

  const w_1_4 = isMobile ? '100%' : '25%';

  return (
    <Layout>
      <ListHeader label={'Empresa'}>
        <DefaultButton ml={4} to={`/empresas`}> Voltar </DefaultButton>
      </ListHeader>

      <Loader isLoading={isLoading} />

      {empresa && <Formik
        enableReinitialize
        initialValues={empresa}
        onSubmit={(val, { setErrors }) => {
          const validation = validateForm({ nome: 'required' }, val)
          if (Object.keys(validation).length > 0) {
            setErrors(validation)
            return;
          }

          dispatch(sistemaActions.saveEmpresaRequest({
            ...val,
            changed: true,
          }));
        }}
      >
        {({ values }) => (
          <Form>
            <Flex width="full" wrap="wrap">
              <InputField label="Nome" name="nome" width={w_1_4} />
              <InputField label="CPF/CNPJ" name="cpf_cnpj" width={w_1_4} />
              <InputField label="Celular" name="celular" width={w_1_4} />
              <InputField label="Email" name="email" width={w_1_4} />

              <Flex width={"full"} />
              <Box
                bg={"white"}
                mt={2}
                width={"full"}
                position="sticky"
                bottom="10px"
                zIndex="10"
                p={2}
              >
                <DefaultButton type="submit">Salvar empresa</DefaultButton>
                {values.id && <ButtonHistorico par="empresa" idpar={values.id} ml={2} />}
              </Box>
            </Flex>
          </Form>
        )}
      </Formik>}
    </Layout>
  )
}