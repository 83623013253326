import { useSelector } from "react-redux";
import { RootState } from "../../../app/mainReducer";
import { DialogModal } from "../../../sistema/components/DialogModal";
import { DefaultButton } from "../../../sistema/components/DefaultButton";
import { PedidoItem } from "../../../entradaesaida/types/pedido_item";
import { Form, Formik, useField } from "formik";
import { useEffect, useState } from "react";
import { novoPedidoItem } from "../../../entradaesaida/data/pedido_item";
import { ProdutoBobjeto } from "../../../entradaesaida/components/ProdutoBobjeto";
import { Button } from "@chakra-ui/react";
import { ListHeaderSecondary } from "../../../sistema/components/ListHeaderSecondary";
import InputField from "../../../sistema/components/InputField";
import InputNumber from "../../../sistema/components/InputNumber";
import { formatValor } from "../../../utils/formatValor";
import { FiCheck } from "react-icons/fi";
import { mensagemErro } from "../../../utils/toasts";
import { ShowArray } from "../../../sistema/components/ShowArray";
import InputSelect from "../../../sistema/components/InputSelect";
import { getDecimalPlacesOfSystem } from "../../../sistema/utils/getDecimalPlacesOfSystem";

type ItemAddProps = {
  onClose: () => void;
  isOpen: boolean;
  id: string;
  isEditing: boolean;
};

export const ItemForm = ({ isOpen, onClose, id, isEditing }: ItemAddProps) => {
  const [, { value: orderItems }, { setValue }] = useField("itens");

  const isMobile = useSelector((state: RootState) => state.sistema.isMobile);

  const locationsStock = useSelector(
    (state: RootState) => state.pdv?.locationsStock ?? []
  );

  const [currentItem, setCurrentItem] = useState<PedidoItem | null>(null);
  const [currentIndex, setCurrentIndex] = useState<string | number>(-1);

  useEffect(() => {
    if (id == "novo") {
      setCurrentItem(novoPedidoItem());
      setCurrentIndex("novo");
    } else {
      setCurrentItem(orderItems[id as any as number]);
      setCurrentIndex(id as any as number);
    }
  }, [id]);

  const [focusOnProduct, setFocusOnProduct] = useState(false);

  useEffect(() => {
    if (currentItem && !currentItem?.produto_id) {
      setFocusOnProduct(true);
    }
  }, [currentItem]);

  const alreadyHasTheItem = (produto_id: number) => {
    if (produto_id == currentItem?.produto_id) {
      return false;
    }

    if (orderItems.some((i: PedidoItem) => i.produto_id == produto_id)) {
      return true;
    }

    return false;
  };

  const onSaveModal = (form: any) => {
    const values = JSON.parse(JSON.stringify(orderItems)).filter(
      (i: any) => !i?.deleted
    );

    form = {
      ...form,
      changed: true,
    };

    console.log(currentIndex, currentItem, form);

    if (currentIndex == "novo") {
      values.push(form);
      setValue(values);
      onCloseModal();
      return;
    }

    values[currentIndex] = {
      ...values[currentIndex],
      ...form,
    };
    setValue(values);
    onCloseModal();
  };

  const getTotalValue = (
    quantidade: number,
    valor_unitario: number,
    valor_desconto: number
  ) => {
    const totalValue = (quantidade ?? 0) * (valor_unitario ?? 0);

    return parseFloat(
      (totalValue - (valor_desconto ?? 0)).toFixed(getDecimalPlacesOfSystem())
    );
  };

  const onCloseModal = () => {
    setCurrentItem(null);
    setCurrentIndex(-1);
    onClose();
  };

  return (
    <DialogModal
      show={isOpen}
      close={onCloseModal}
      maxWidth="2xl"
      title={
        <ListHeaderSecondary>
          {id == "novo" ? "Adicionar produto" : "Editar produto"}
          <DefaultButton
            size={"sm"}
            onClick={onCloseModal}
            ml={2}
            type="button"
          >
            Voltar
          </DefaultButton>
        </ListHeaderSecondary>
      }
      content={
        <div className="w-full flex flex-wrap">
          {isEditing && (
            <div className="w-full">
              <Formik
                enableReinitialize
                initialValues={{ product_index: "" }}
                onSubmit={() => {}}
              >
                {({ values, setFieldValue }) => (
                  <Form style={{ width: "100%" }}>
                    <InputSelect
                      label="Selecione o produto do carrinho a editar"
                      name="product_index"
                      width={"100%"}
                      noPlaceholer
                      onChangeVal={(value) => {
                        if (!value) {
                          setCurrentItem(null);
                          setCurrentIndex(-1);
                          return;
                        }
                        setCurrentItem(orderItems[parseInt(value)]);
                        setCurrentIndex(parseInt(value));
                      }}
                    >
                      <option value="">Selecione...</option>
                      {(orderItems as PedidoItem[]).map((orderItem, index) => (
                        <option value={`${index}`}>
                          {orderItem?.item?.codigo ?? ""} -{" "}
                          {orderItem?.item?.nome}{" "}
                          {(orderItem?.item?.array_unit_measure ?? 0) > 0 && (
                            <>
                              (
                              <ShowArray
                                arrayName="array_unit_measure"
                                value={
                                  (orderItem?.item?.array_unit_measure ?? 0) * 1
                                }
                              />
                              )
                            </>
                          )}
                        </option>
                      ))}
                    </InputSelect>
                  </Form>
                )}
              </Formik>
            </div>
          )}

          {currentItem && (
            <Formik
              enableReinitialize
              initialValues={currentItem}
              onSubmit={(values, { setErrors }) => {
                if (values.valor_total <= 0) {
                  mensagemErro("O valor total precisa ser maior que zero!");
                  return;
                }

                if (!values.produto_id) {
                  mensagemErro("O produto é obrigatório ser preenchido!");
                  return;
                }

                if (alreadyHasTheItem(values.produto_id as number)) {
                  mensagemErro(
                    "Produto duplicado! O produto já está no carrinho, não é possível duplicar, edite-o."
                  );
                  return;
                }

                if (values.item?.controls_stock == 1) {
                  if (!values.stock_location_id) {
                    mensagemErro("Selecione o local de estoque!");
                    return;
                  }
                }

                console.log(values);
                onSaveModal(values);
              }}
            >
              {({ values, setFieldValue }) => (
                <Form style={{ width: "100%" }}>
                  <div className="w-full min-h-[50vh]">
                    {!isEditing && (
                      <ProdutoBobjeto
                        setFocus={focusOnProduct}
                        label="Pesquisar Produto"
                        name="produto_id"
                        width="100%"
                        onSelectItem={(product) => {
                          setFieldValue("item", product);
                          // setFieldValue("quantidade", 1);
                          // setFieldValue("valor_desconto", 0);

                          if (product) {
                            const price = parseFloat(
                              parseFloat(product.preco).toFixed(
                                getDecimalPlacesOfSystem()
                              )
                            );

                            setFieldValue("valor_unitario", price);
                            setFieldValue("valor_total", price);
                            if (
                              product.controls_stock == 1 &&
                              !values.stock_location_id &&
                              locationsStock.length > 0
                            ) {
                              setFieldValue(
                                "stock_location_id",
                                locationsStock[0].id
                              );
                            }
                          } else {
                            setFieldValue("valor_unitario", 0);
                            setFieldValue("valor_total", 0);
                          }
                        }}
                      />
                    )}

                    <div className="w-full flex flex-wrap p-3">
                      {values.produto_id && (
                        <div className="w-full flex flex-wrap border-t mt-1 pt-3">
                          {/* <span className="w-full text-center uppercase font-semibold text-gray-700">
                            Produto selecionado
                          </span> */}
                          <InputField
                            disabled
                            name="item.codigo"
                            label="Código:"
                            width={isMobile ? "100%" : "15%"}
                          />
                          <InputField
                            disabled
                            name="item.nome"
                            label="Descrição:"
                            width={
                              isMobile
                                ? "100%"
                                : values?.item?.controls_stock == 1
                                ? "55%"
                                : "85%"
                            }
                          />
                          {values?.item?.controls_stock == 1 && (
                            <InputSelect
                              label="Estoque de saída"
                              name="stock_location_id"
                              width={isMobile ? "100%" : "30%"}
                              noPlaceholer
                            >
                              {locationsStock.map((location) => (
                                <option
                                  key={`${location.id}`}
                                  value={location.id}
                                >
                                  {location.name}
                                </option>
                              ))}
                            </InputSelect>
                          )}
                          <InputNumber
                            label="Valor Unitário"
                            name="valor_unitario"
                            width={isMobile ? "100%" : "33.33%"}
                            decimalPlaces={getDecimalPlacesOfSystem()}
                            onChangeVal={(value) =>
                              setFieldValue(
                                "valor_total",
                                getTotalValue(
                                  values.quantidade,
                                  value,
                                  values.valor_desconto
                                )
                              )
                            }
                          />
                          <InputNumber
                            label="Quantidade"
                            name="quantidade"
                            width={isMobile ? "100%" : "33.33%"}
                            decimalPlaces={getDecimalPlacesOfSystem()}
                            onChangeVal={(value) =>
                              setFieldValue(
                                "valor_total",
                                getTotalValue(
                                  value,
                                  values.valor_unitario,
                                  values.valor_desconto
                                )
                              )
                            }
                          />
                          <InputNumber
                            label="Valor Desconto"
                            name="valor_desconto"
                            width={isMobile ? "100%" : "33.33%"}
                            decimalPlaces={getDecimalPlacesOfSystem()}
                            onChangeVal={(value) =>
                              setFieldValue(
                                "valor_total",
                                getTotalValue(
                                  values.quantidade,
                                  values.valor_unitario,
                                  value
                                )
                              )
                            }
                          />

                          <div className="w-full border-b mt-4 mb-1"></div>

                          <div className="w-full flex justify-between py-2 font-semibold">
                            <span>
                              Quantidade{" "}
                              {(values.item?.array_unit_measure ?? 0) > 0 && (
                                <>
                                  (
                                  <ShowArray
                                    arrayName="array_unit_measure"
                                    value={
                                      (values.item?.array_unit_measure ?? 0) * 1
                                    }
                                  />
                                  )
                                </>
                              )}
                              :{" "}
                            </span>
                            <span>
                              {" "}
                              {formatValor(
                                values.quantidade,
                                getDecimalPlacesOfSystem()
                              )}{" "}
                            </span>
                          </div>
                          <div className="w-full flex justify-between py-2 font-semibold">
                            <span>Total: </span>
                            <span>
                              R${" "}
                              {formatValor(
                                (values.quantidade ?? 0) *
                                  (values.valor_unitario ?? 0),
                                getDecimalPlacesOfSystem()
                              )}
                            </span>
                          </div>
                          <div className="w-full flex justify-between py-2 font-semibold">
                            <span>Desconto: </span>
                            <span className="text-red-300">
                              - R${" "}
                              {formatValor(
                                values.valor_desconto,
                                getDecimalPlacesOfSystem()
                              )}
                            </span>
                          </div>

                          <div className="w-full border-b mt-1"></div>

                          <div className="w-full flex justify-between py-3 text-lg font-semibold">
                            <span>Valor Final: </span>
                            <span className="text-green-700 text-2xl">
                              R${" "}
                              {formatValor(
                                values.valor_total,
                                getDecimalPlacesOfSystem()
                              )}
                            </span>
                          </div>

                          <Button
                            display={"flex"}
                            gap={1}
                            alignItems={"center"}
                            rounded={"2px"}
                            width={"full"}
                            colorScheme="green"
                            type="submit"
                          >
                            <FiCheck />{" "}
                            {id == "novo"
                              ? "Adicionar produto"
                              : "Salvar produto"}
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      }
      footer={
        <div className="w-full">
          <DefaultButton onClick={onCloseModal} size={"sm"} type="button">
            Voltar
          </DefaultButton>
        </div>
      }
    />
  );
};
