import { useIsAuth } from "../../hooks/useIsAuth";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FiTrash } from "react-icons/fi";
import { cadastroActions } from "../reducer";
import Layout from "../../sistema/components/Layout";
import { ListHeader } from "../../sistema/components/ListHeader";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import Loader from "../../sistema/components/Loader";
import { ListInput } from "../../sistema/components/ListInput";
import ResponsiveTable, {
  TableHeaders,
} from "../../sistema/components/ResponsiveTable";
import { Pagination } from "../../sistema/components/Pagination";
import { ConfirmButton } from "../../sistema/components/ConfirmButton";
import { Seller } from "../types/seller";
import { formatCelular } from "../../utils/formatCelular";
import { Badge } from "@chakra-ui/react";
import { formatValor } from "../../utils/formatValor";

export const SellerList = () => {
  useIsAuth();

  const sellers = useSelector((state: RootState) => state.cadastro?.sellers);
  const isLoading = useSelector(
    (state: RootState) => !!state.cadastro.isLoading
  );
  const success = useSelector((state: RootState) => !!state.cadastro.success);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [termo, setTermo] = useState("");

  useEffect(() => {
    dispatch(
      cadastroActions.sellersRequest({
        page,
        termo,
      })
    );
  }, [dispatch, page, termo]);

  useEffect(() => {
    if (!success) {
      return;
    }
    dispatch(
      cadastroActions.sellersRequest({
        page,
        termo,
      })
    );
  }, [dispatch, success, page, termo]);

  const headers: TableHeaders<Seller>[] = [
    {
      label: "Código",
      wrapped: true,
      render: (item) => item.code,
    },
    {
      label: "Nome",
      render: (item) => (
        <React.Fragment>
          {item.name} <br />
          <Badge colorScheme="green">
            {formatValor(item.comission_percentage)}% comissão
          </Badge>
        </React.Fragment>
      ),
    },
    {
      label: "Celular",
      wrapped: true,
      render: (item) => formatCelular(item.cellphone),
    },
    {
      label: "Ativo?",
      wrapped: true,
      render: (item) => (item.active == 1 ? "Sim" : "Não"),
    },
    {
      label: "Ações",
      wrapped: true,
      render: (item, index) => (
        <>
          {
            <ConfirmButton confirm={() => onDeleteItem(item.id as number)}>
              <FiTrash />
            </ConfirmButton>
          }
        </>
      ),
      notClicable: true,
    },
  ];

  const onDeleteItem = (id: number) => {
    dispatch(cadastroActions.deleteSellerRequest(id));
  };

  const navigate = useNavigate();

  return (
    <Layout>
      <ListHeader label={`Vendedores`}>
        <DefaultButton ml={4} to={`/sellers/novo`}>
          Novo
        </DefaultButton>
      </ListHeader>

      <Loader isLoading={isLoading} />

      <ListInput label="Filtrar por nome ou código" onSubmit={setTermo} />

      {sellers && (
        <ResponsiveTable
          isSmall
          onClick={(item: any) => navigate(`/sellers/${item.item.id}`)}
          headers={headers}
          data={sellers.data}
        />
      )}

      {sellers && <Pagination info={sellers} paginate={setPage} />}
    </Layout>
  );
};
