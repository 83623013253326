import React from "react";
import { Button, useDisclosure } from "@chakra-ui/react";
import { FiCheck } from "react-icons/fi";
import { PaymentInformation } from "./PaymentInformation";
import { useFormikContext } from "formik";

export const FinalizeSale = () => {
  const formik = useFormikContext();

  const {
    isOpen: isOpenPaymentInformation,
    onOpen: onOpenPaymentInformation,
    onClose: onClosePaymentInformation,
  } = useDisclosure();

  const onFinishPaymentInformation = () => {
    onClosePaymentInformation();
    formik.submitForm();
  };

  return (
    <React.Fragment>
      <Button
        display={"flex"}
        gap={1}
        alignItems={"center"}
        rounded={"2px"}
        width={"full"}
        py={8}
        colorScheme="green"
        fontSize={"2xl"}
        type="button"
        onClick={onOpenPaymentInformation}
      >
        <FiCheck /> Finalizar venda
      </Button>

      <PaymentInformation
        isOpen={isOpenPaymentInformation}
        onClose={onClosePaymentInformation}
        onFinish={onFinishPaymentInformation}
      />
    </React.Fragment>
  );
};
