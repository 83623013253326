import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { CamposPersonalizadosForm } from "./CamposPersonalizadosForm";
import { DefaultButton } from "./DefaultButton";
import { ListHeaderSecondary } from "./ListHeaderSecondary";
import { useState } from "react";

type CamposPersonalizadosFiltrosProps = {
  par?: string;
  onFilter: () => void;
};

export const CamposPersonalizadosFiltros = ({
  par = "pessoa",
  onFilter,
}: CamposPersonalizadosFiltrosProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [quantidadeFiltrosAtivos, setQuantidadeFiltrosAtivos] =
    useState(0);

  return (
    <>
      <Button onClick={onOpen} colorScheme="blackAlpha">
        Filtros avançados ({quantidadeFiltrosAtivos})
      </Button>

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        size={"md"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ListHeaderSecondary label={"Filtros avançados"} />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex width={"full"} wrap={"wrap"}>
              <CamposPersonalizadosForm
                atualiza={false}
                retiraOpcaoAdicionarMais={true}
                tamanho="50%"
                par={par}
                idp={""}
                setQuantidadeFiltrosAtivos={setQuantidadeFiltrosAtivos}
              />
              <Flex width={"full"} my={2} />
              <DefaultButton
                onClick={() => {
                  onFilter();
                  onClose();
                }}
                width={"full"}
              >
                Filtrar
              </DefaultButton>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
