import { PedidoItem } from "../../../entradaesaida/types/pedido_item";
import ResponsiveTable, {
  TableHeaders,
} from "../../../sistema/components/ResponsiveTable";
import { FiEdit2, FiTrash } from "react-icons/fi";
import { formatValor } from "../../../utils/formatValor";
import { ShowArray } from "../../../sistema/components/ShowArray";
import { ConfirmationModal } from "../../../sistema/components/ConfirmationModal";
import React, { useState } from "react";
import { SecondaryButton } from "../../../sistema/components/SecondaryButton";
import { DangerButton } from "../../../sistema/components/DangerButton";
import { useField } from "formik";
import { getDecimalPlacesOfSystem } from "../../../sistema/utils/getDecimalPlacesOfSystem";

type ItemProps = {
  items: PedidoItem[];
  onEditItem: (value: number) => void;
};

export const ItemsView = ({ items, onEditItem }: ItemProps) => {
  const [, { value: orderItems }, { setValue: setValueItems }] =
    useField("itens");

  const [itemIndexToBeingDeleted, setItemIndexToBeingDeleted] =
    useState<number>(-1);
  const deleteItem = () => {
    const values: PedidoItem[] = JSON.parse(JSON.stringify(orderItems));

    values.splice(itemIndexToBeingDeleted, 1);

    setValueItems(values);

    setItemIndexToBeingDeleted(-1);
  };

  const headers: TableHeaders<PedidoItem>[] = [
    {
      label: "Ações",
      wrapped: true,
      render: (item, index) => (
        <div className="flex items-center">
          <button
            className="text-blue-700 hover:bg-blue-100 rounded-md p-1"
            onClick={() => onEditItem(index)}
            type="button"
          >
            <FiEdit2 />
          </button>
          <button
            className="text-red-700 hover:bg-red-100 rounded-md p-1"
            onClick={() => setItemIndexToBeingDeleted(index)}
            type="button"
          >
            <FiTrash />
          </button>
        </div>
      ),
      notClicable: true,
    },
    {
      label: "Código",
      wrapped: true,
      render: (item) => item.item?.codigo,
      notClicable: true,
    },
    {
      label: "Produto",
      wrapped: false,
      render: (item) => item.item?.nome,
      notClicable: true,
    },
    {
      label: "QTD.",
      wrapped: true,
      render: (item) =>
        `${formatValor(item.quantidade, getDecimalPlacesOfSystem())}`,
      notClicable: true,
    },
    {
      label: "UN.",
      wrapped: false,
      render: (item) => (
        <ShowArray
          arrayName="array_unit_measure"
          value={(item.item?.array_unit_measure ?? 0) * 1}
        />
      ),
      notClicable: true,
    },
    {
      label: "DESCONTO",
      wrapped: true,
      render: (item) =>
        `${formatValor(item.valor_desconto, getDecimalPlacesOfSystem())}`,
      notClicable: true,
    },
    {
      label: "Unitário",
      wrapped: true,
      render: (item) =>
        `${formatValor(item.valor_unitario, getDecimalPlacesOfSystem())}`,
      notClicable: true,
    },
    {
      label: "Total",
      wrapped: true,
      render: (item) =>
        `${formatValor(item.valor_total, getDecimalPlacesOfSystem())}`,
      notClicable: true,
    },
  ];

  return (
    <div className="w-full">
      <ResponsiveTable isSmall headers={headers} data={items} />

      <ConfirmationModal
        show={itemIndexToBeingDeleted !== -1}
        close={() => setItemIndexToBeingDeleted(-1)}
        title={"Remover Produto"}
        content={"Você tem certeza que deseja remover este produto?"}
        footer={
          <React.Fragment>
            <SecondaryButton
              type="button"
              externalClass="w-auto"
              onClick={() => setItemIndexToBeingDeleted(-1)}
            >
              Cancelar
            </SecondaryButton>

            <DangerButton
              type="button"
              externalClass="ms-3"
              onClick={deleteItem}
            >
              Remover
            </DangerButton>
          </React.Fragment>
        }
      />
    </div>
  );
};
