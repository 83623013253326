import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Login } from "../sistema/views/Login";
import { Logout } from "../sistema/views/Logout";
import { UsuarioList } from "../sistema/views/UsuarioList";
import { UsuarioEdit } from "../sistema/views/UsuarioEdit";
import useIsMobile from "../hooks/useIsMobile";
import Home from "../sistema/views/Home";
import { Parametros } from "../sistema/views/Parametros";
import { PessoaList } from "../cadastros/views/PessoaList";
import { PessoaEdit } from "../cadastros/views/PessoaEdit";
import { TabelaPrecoList } from "../cadastros/views/TabelaPrecoList";
import { TabelaPrecoEdit } from "../cadastros/views/TabelaPrecoEdit";
import { CidadeList } from "../cadastros/views/CidadeList";
import { CidadeEdit } from "../cadastros/views/CidadeEdit";
import { RotaList } from "../cadastros/views/RotaList";
import { RotaEdit } from "../cadastros/views/RotaEdit";
import { FichaCrm } from "../crm/views/FichaCrm";
import { Agendamentos } from "../crm/views/Agendamentos";
import { ListaList } from "../cadastros/views/ListaList";
import { ListaEdit } from "../cadastros/views/ListaEdit";
import { RelatorioRelacaoCliente } from "../crm/views/RelatorioRelacaoCliente";
import { RelatorioRelacaoPedido } from "../crm/views/RelatorioRelacaoPedido";
import { ImportadorPessoaList } from "../sistema/views/ImportadorPessoaList";
import { RelatorioRelacaoContatosRealizados } from "../crm/views/RelatorioRelacaoContatosRealizados";
import { useNotificaAgendamentos } from "../hooks/useNotificaAgendamentos";
import { AssinaturasList } from "../sistema/views/AssinaturasList";
import { AssinaturasEdit } from "../sistema/views/AssinaturasEdit";
import { EmpresaList } from "../sistema/views/EmpresaList";
import { EmpresaEdit } from "../sistema/views/EmpresaEdit";
import { Mensalidade } from "../sistema/views/Mensalidade";
import { useBuscaMensalidadesEmAberto } from "../hooks/useBuscaMensalidadesEmAberto";
import { ProdutosLIst } from "../entradaesaida/views/ProdutosList";
import { ProdutosEdit } from "../entradaesaida/views/ProdutosEdit";
import { SacolasList } from "../entradaesaida/views/SacolasList";
import { SacolasEdit } from "../entradaesaida/views/SacolasEdit";
import { FinanceiroEdit } from "../financeiro/views/FinanceiroEdit";
import { FinanceiroList } from "../financeiro/views/FinanceiroList";
import { NotaPromissoriaList } from "../entradaesaida/views/NotaPromissoriaList";
import { NotaPromissoriaEdit } from "../entradaesaida/views/NotaPromissoriaEdit";
import { ImportadorList } from "../sistema/views/Importador";
import { Cart } from "../pdv/views/Cart";
import { SellerEdit } from "../cadastros/views/SellerEdit";
import { SellerList } from "../cadastros/views/SellerList";
import { StockLocationList } from "../entradaesaida/views/StockLocationList";
import { StockLocationEdit } from "../entradaesaida/views/StockLocationEdit";
import { StockFind } from "../entradaesaida/views/StockFind";
import { StockMovimentation } from "../entradaesaida/views/StockMovimentation";

export const App: React.FC<{}> = () => {
  useIsMobile();
  useNotificaAgendamentos();
  useBuscaMensalidadesEmAberto();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/parametros" element={<Parametros />} />
        <Route path="/usuarios" element={<UsuarioList />} />
        <Route path="/usuarios/:id" element={<UsuarioEdit />} />
        <Route path="/pessoas/:tipo" element={<PessoaList />} />
        <Route path="/pessoas/:tipo/:id" element={<PessoaEdit />} />
        <Route path="/tabelas" element={<TabelaPrecoList />} />
        <Route path="/tabelas/:id" element={<TabelaPrecoEdit />} />
        <Route path="/cidades" element={<CidadeList />} />
        <Route path="/cidades/:id" element={<CidadeEdit />} />
        <Route path="/rotas" element={<RotaList />} />
        <Route path="/rotas/:id" element={<RotaEdit />} />
        <Route path="/fichas-crm" element={<FichaCrm />} />
        <Route path="/fichas-crm/:id" element={<FichaCrm />} />
        <Route path="/agendamentos" element={<Agendamentos />} />
        <Route path="/agendamentos/:ids" element={<Agendamentos />} />
        <Route path="/listas" element={<ListaList />} />
        <Route path="/listas/:id" element={<ListaEdit />} />
        <Route path="/relacao-pedidos" element={<RelatorioRelacaoPedido />} />
        <Route path="/relacao-pessoas/:tipo" element={<RelatorioRelacaoCliente />} />
        <Route path="/relacao-contatos-realizados" element={<RelatorioRelacaoContatosRealizados />} />
        <Route path="/importador-pessoas" element={<ImportadorPessoaList />} />
        <Route path="/importador" element={<ImportadorList />} />
        <Route path="/sistema-assinatura" element={<AssinaturasList />} />
        <Route path="/sistema-assinatura/:id" element={<AssinaturasEdit />} />
        <Route path="/empresas" element={<EmpresaList />} />
        <Route path="/empresas/:id" element={<EmpresaEdit />} />
        <Route path="/mensalidade" element={<Mensalidade />} />
        <Route path="/produtos" element={<ProdutosLIst />} />
        <Route path="/produtos/:id" element={<ProdutosEdit />} />
        <Route path="/sacolas" element={<SacolasList />} />
        <Route path="/sacolas/:id" element={<SacolasEdit />} />
        <Route path="/financeiro/:tipo" element={<FinanceiroList />} />
        <Route path="/financeiro/:tipo/:id" element={<FinanceiroEdit />} />
        <Route path="/notas-promissorias" element={<NotaPromissoriaList />} />
        <Route path="/notas-promissorias/:id" element={<NotaPromissoriaEdit />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/sellers" element={<SellerList />} />
        <Route path="/sellers/:id" element={<SellerEdit />} />
        <Route path="/stock-location" element={<StockLocationList />} />
        <Route path="/stock-location/:id" element={<StockLocationEdit />} />
        <Route path="/stock-find" element={<StockFind />} />
        <Route path="/stock-find/:id" element={<StockFind />} />
        <Route path="/stock-movimentation" element={<StockMovimentation />} />
      </Routes>
    </BrowserRouter>
  )
}