import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { mensagemErro, mensagemSucesso } from "../utils/toasts";
import { Ficha } from "./types/ficha";
import {
  IFichaCrmCriarFiltros,
  IPessoaFichaCrmCriar,
} from "./components/FichaCrmCriar";
import {
  IFichaCriarSelectInputs,
  IResponseAgendamento,
  IResponseFicha,
} from "./saga";
import { IFiltros } from "./components/FichaCrmGerenciar";
import { IFiltrosAgendamentos } from "./views/Agendamentos";
import {
  IFiltrosRelatorioCliente,
  IPessoaRelatorioCliente,
} from "./views/RelatorioRelacaoCliente";
import { ApiPaginationRes } from "../sistema/types/apiPaginationRes";
import { Pedido } from "../entradaesaida/types/pedido";
import { IFiltrosRelatorioPedido } from "./views/RelatorioRelacaoPedido";
import { IFiltrosRelatorioContatosRealizados } from "./views/RelatorioRelacaoContatosRealizados";
import { Pessoa } from "../cadastros/types/pessoa";
import { Agendamento } from "./types/agendamento";

export interface IResponseRelatorioPedido {
  total_geral: {
    quantidade: number;
    valor: number;
    peso: number;
  };
  total_faturado: {
    quantidade: number;
    valor: number;
    peso: number;
  };
  total_carteira: {
    quantidade: number;
    valor: number;
    peso: number;
  };
  ufs: { label: string; value: string[] };
  itens: ApiPaginationRes<Pedido[]>;
}

export interface IResponseRelatorioContatosRealizados {
  total_contatos_realizados: number;
  ufs: { label: string; value: string }[];
  itens: ApiPaginationRes<Pessoa[]>;
}

type TInitialState = {
  error?: string;
  success?: string;
  isLoading?: boolean;
  fichas?: Ficha[];
  ficha?: IResponseFicha | null;
  novaFichaPessoas?: IPessoaFichaCrmCriar[];
  fichaSelectInputs?: IFichaCriarSelectInputs;
  agendamentos?: IResponseAgendamento | null;
  filtrosFichaGerenciamento?: IFiltros | null;
  relatorioClientes?: ApiPaginationRes<IPessoaRelatorioCliente[]>;
  relatorioPedidos?: IResponseRelatorioPedido;
  relatorioContatosRealizados?: IResponseRelatorioContatosRealizados;
  agendamentosANotificar?: Agendamento[];
  agendamentosNotificados?: string[];
  pesoMeta?: number;
};

const initialState: TInitialState = {};

const reducer = createSlice({
  name: "crm",
  initialState,
  reducers: {
    isLoading(state, { payload }: PayloadAction<boolean>) {
      state.isLoading = payload;
    },
    error(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = payload;
      state.success = "";
      mensagemErro(payload);
    },
    success(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = "";
      state.success = payload;
      mensagemSucesso(payload);
    },
    setPesoMeta(state, { payload }: PayloadAction<number>) {
      state.pesoMeta = payload;
    },
    fichasRequest(state) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    fichasRequestSuccess(state, { payload }: PayloadAction<Ficha[]>) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.fichas = payload;
    },
    fichaRequest(state, _: PayloadAction<IFiltros>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    fichaRequestSuccess(state, { payload }: PayloadAction<IResponseFicha>) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.ficha = payload;
    },
    buscaFichaPessoasRequest(state, _: PayloadAction<IFichaCrmCriarFiltros>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    buscaFichaPessoasRequestSuccess(
      state,
      { payload }: PayloadAction<IPessoaFichaCrmCriar[]>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.novaFichaPessoas = payload;
    },
    buscaFichaSelectInputsRequest(state) {
      // state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    buscaFichaSelectInputsRequestSuccess(
      state,
      { payload }: PayloadAction<IFichaCriarSelectInputs>
    ) {
      // state.isLoading = false;
      state.error = "";
      state.success = "";
      state.fichaSelectInputs = payload;
    },
    retiraClienteDaLista(state, { payload }: PayloadAction<number>) {
      state.novaFichaPessoas?.splice(payload, 1);
    },
    agendamentosRequest(state, _: PayloadAction<IFiltrosAgendamentos>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    agendamentosRequestSuccess(
      state,
      { payload }: PayloadAction<IResponseAgendamento>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.agendamentos = payload;
    },
    setaFiltrosFichaGerenciamento(state, { payload }: PayloadAction<IFiltros>) {
      state.filtrosFichaGerenciamento = payload;
    },

    buscaRelatorioClientesRequest(
      state,
      _: PayloadAction<IFiltrosRelatorioCliente>
    ) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    buscaRelatorioClientesRequestSuccess(
      state,
      { payload }: PayloadAction<ApiPaginationRes<IPessoaRelatorioCliente[]>>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.relatorioClientes = payload;
    },

    buscaRelatorioPedidosRequest(
      state,
      _: PayloadAction<IFiltrosRelatorioPedido>
    ) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    buscaRelatorioPedidosRequestSuccess(
      state,
      { payload }: PayloadAction<IResponseRelatorioPedido>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.relatorioPedidos = payload;
    },

    buscaRelatorioContatosRealizadosRequest(
      state,
      _: PayloadAction<IFiltrosRelatorioContatosRealizados>
    ) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    buscaRelatorioContatosRealizadosRequestSuccess(
      state,
      { payload }: PayloadAction<IResponseRelatorioContatosRealizados>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.relatorioContatosRealizados = payload;
    },
    setAgendamentoNotificado(state,{payload}: PayloadAction<number | string>) {
      if (!state.agendamentosNotificados) state.agendamentosNotificados = [];

      state.agendamentosNotificados.push(payload as string);
    },
    agendamentosANotificarRequest(state) {
    },
    agendamentosANotificarRequestSuccess(state, {payload}: PayloadAction<Agendamento[]>) {
      state.agendamentosANotificar = payload;
    },
  },
});

export const crmActions = reducer.actions;

export default reducer;
