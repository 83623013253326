import { Seller } from "../types/seller";

export const newSeller = (): Seller => {
  return {
    name: '',
    code: '',
    cellphone: '',
    active: 1,
    comission_percentage: 0,
  }
}