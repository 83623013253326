import { getCurrentData } from "../../utils/data";
import { Financeiro } from "../types/financeiro";

export const novoFinanceiro = (tipo: "pagar" | "receber"): Financeiro => {
  return {
    tipo,
    codigo: "",
    data_emissao: getCurrentData('yyyy-MM-dd'),
    descricao: "",
    tipo_pagamento: 1,
    valor_total: "0",
    pessoa_id: "",
    par: '',
    idp: '',
    seller_id: '',
    parcelas: [],
  };
};
