import { useIsAuth } from "../../hooks/useIsAuth";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FiTrash } from "react-icons/fi";
import { entradaesaidaActions } from "../recucer";
import ResponsiveTable, {
  TableHeaders,
} from "../../sistema/components/ResponsiveTable";
import { ConfirmButton } from "../../sistema/components/ConfirmButton";
import Layout from "../../sistema/components/Layout";
import { ListHeader } from "../../sistema/components/ListHeader";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import Loader from "../../sistema/components/Loader";
import { ListInput } from "../../sistema/components/ListInput";
import { Pagination } from "../../sistema/components/Pagination";
import { CamposPersonalizadosInfoButton } from "../../sistema/components/CamposPersonalizadosInfoButton";
import { ButtonGroup } from "@chakra-ui/react";
import { StockLocation } from "../types/stock_location";

export const StockLocationList = () => {
  useIsAuth();

  const stocksLocation = useSelector(
    (state: RootState) => state.entradaesaida.stocksLocation
  );
  const isLoading = useSelector(
    (state: RootState) => !!state.entradaesaida.isLoading
  );
  const success = useSelector(
    (state: RootState) => !!state.entradaesaida.success
  );
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [termo, setTermo] = useState("");

  useEffect(() => {
    dispatch(
      entradaesaidaActions.stocksLocationRequest({
        page,
        termo,
      })
    );
  }, [dispatch, page, termo]);

  useEffect(() => {
    if (!success) {
      return;
    }
    dispatch(
      entradaesaidaActions.stocksLocationRequest({
        page,
        termo,
      })
    );
  }, [dispatch, success, page, termo]);

  const headers: TableHeaders<StockLocation>[] = [
    {
      label: "Nome",
      wrapped: true,
      render: (item) => item.name,
    },
    {
      label: "Ativo?",
      wrapped: false,
      render: (item) => (item.active == 1 ? "Sim" : "Não"),
    },
    {
      label: "Ações",
      wrapped: true,
      render: (item, index) => (
        <>
          {
            <ButtonGroup>
              <ConfirmButton confirm={() => onDeleteItem(item.id as number)}>
                <FiTrash />
              </ConfirmButton>
              <CamposPersonalizadosInfoButton
                camposPersonalizados={item?.camposPersonalizados ?? []}
              />
            </ButtonGroup>
          }
        </>
      ),
      notClicable: true,
    },
  ];

  const onDeleteItem = (id: number) => {
    dispatch(entradaesaidaActions.deleteStockLocationRequest(id));
  };

  const navigate = useNavigate();

  return (
    <Layout>
      <ListHeader label="Locais de Estoque">
        <DefaultButton ml={4} to="/stock-location/novo">
          Novo
        </DefaultButton>
      </ListHeader>

      <Loader isLoading={isLoading} />

      <ListInput
        label="Filtrar pelo nome"
        onSubmit={setTermo}
      />

      {stocksLocation && (
        <ResponsiveTable
          onClick={(item: any) => navigate(`/stock-location/${item.item.id}`)}
          headers={headers}
          data={stocksLocation.data}
        />
      )}

      {stocksLocation && (
        <Pagination info={stocksLocation} paginate={setPage} />
      )}
    </Layout>
  );
};
