import React, { useEffect } from "react";
import { DefaultButton } from "../../../sistema/components/DefaultButton";
import { useDisclosure } from "@chakra-ui/react";
import { ListHeader } from "../../../sistema/components/ListHeader";
import { DialogModal } from "../../../sistema/components/DialogModal";
import { PessoaForm } from "../../../cadastros/components/PessoaForm";
import { useDispatch, useSelector } from "react-redux";
import { cadastroActions } from "../../../cadastros/reducer";
import { RootState } from "../../../app/mainReducer";
import { Pessoa } from "../../../cadastros/types/pessoa";

type CreateCustomerProps = {
  onSavedCustomer: (customer: Pessoa) => void;
};

export const CreateCustomer = ({ onSavedCustomer }: CreateCustomerProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const dispatch = useDispatch();

  const pessoa = useSelector(
    (state: RootState) => state.cadastro.pessoa || null
  );

  const onRegisterCustomer = () => {
    dispatch(cadastroActions.pessoaRequest("novo"));
    onOpen();
  };

  const sucessoCadastro = useSelector(
    (state: RootState) => state.cadastro?.success
  );

  useEffect(() => {
    if (sucessoCadastro == "Registro salvo com sucesso." && pessoa?.id) {
      onClose();
      onSavedCustomer(pessoa);
      dispatch(cadastroActions.success(""));
    }
  }, [sucessoCadastro, pessoa, onClose]);

  return (
    <React.Fragment>
      <DefaultButton
        onClick={onRegisterCustomer}
        borderRadius={"2px"}
        m={"2px"}
        size={"sm"}
      >
        Cadastrar um novo
      </DefaultButton>

      <DialogModal
        show={isOpen}
        close={onClose}
        maxWidth="4xl"
        title={
          <ListHeader>
            Cadastrar cliente{" "}
            <DefaultButton type="button" onClick={onClose}>
              Voltar
            </DefaultButton>
          </ListHeader>
        }
        content={
          <div className="w-full">
            <div className="text-lg text-gray-700 my-4">
              <PessoaForm isModal={true} pessoa={pessoa} tipo="clientes" />
            </div>
          </div>
        }
        footer={
          <div className="w-full">
            <DefaultButton type="button" onClick={onClose}>
              Voltar
            </DefaultButton>
          </div>
        }
      />
    </React.Fragment>
  );
};
