import { BobjetoPadrao } from "../../sistema/components/BobjetoPadrao";
import { Pessoa } from "../types/pessoa";

interface IProps {
  width?: string;
  name?: string;
  label?: string;
  placeholder?: string;
  includes?: string;
  onSelectItem?: (item: Pessoa) => void;
  onSelectItemOnLoad?: (item: Pessoa) => void;
}

export const PessoaBobjeto = ({
  width = "100%",
  name = "pessoa_id",
  label = "Pessoa",
  placeholder = "",
  includes = "",
  onSelectItem,
  onSelectItemOnLoad,
}: IProps) => {
  const onSelect = (item: Pessoa) => {
    if (typeof onSelectItem === "function") {
      onSelectItem(item);
    }
    if (typeof onSelectItemOnLoad === "function") {
      onSelectItemOnLoad(item);
    }
  };

  return (
    <BobjetoPadrao
      onSelectItem={onSelect}
      onSelectItemOnLoad={onSelect}
      endpoint="pessoas"
      placeholder={placeholder ? placeholder : label}
      label={label}
      name={name}
      includes={includes}
      visualizacao={(item: Pessoa) => (
        <>
          {item?.nome} - {item?.status}
        </>
      )}
      width={width}
    />
  );
};
