import { BobjetoPadrao } from "../../sistema/components/BobjetoPadrao";
import { ShowArray } from "../../sistema/components/ShowArray";
import { getDecimalPlacesOfSystem } from "../../sistema/utils/getDecimalPlacesOfSystem";
import { formatValor } from "../../utils/formatValor";
import { Produto } from "../types/produto";

interface IProps {
  width?: string;
  name?: string;
  label?: string;
  onSelectItem?: (item: Produto) => void;
  onSelectItemOnLoad?: (item: Produto) => void;
  setFocus?: boolean;
}

export const ProdutoBobjeto = ({
  width = "100%",
  name = "produto_id",
  label = "Produto",
  onSelectItem,
  onSelectItemOnLoad,
  setFocus = false,
}: IProps) => {
  const onSelect = (item: Produto) => {
    if (typeof onSelectItem === "function") {
      onSelectItem(item);
    }
  };

  const onSelectOnLoad = (item: Produto) => {
    if (typeof onSelectItemOnLoad === "function") {
      onSelectItemOnLoad(item);
    }
  };

  return (
    <BobjetoPadrao
      onSelectItem={onSelect}
      onSelectItemOnLoad={onSelectOnLoad}
      endpoint="produtos"
      label={label}
      name={name}
      setFocus={setFocus}
      visualizacao={(item: Produto) => (
        <>
          {item?.codigo ?? ""} - {item?.nome}{" "}
          {(item?.array_unit_measure ?? 0) > 0 && (
            <>
              (
              <ShowArray
                arrayName="array_unit_measure"
                value={(item?.array_unit_measure ?? 0) * 1}
              />
              )
            </>
          )}{" "}
          - {formatValor(item?.preco, getDecimalPlacesOfSystem())}
        </>
      )}
      width={width}
    />
  );
};
