import { Box, Card, CardBody, CardHeader, Flex, Grid, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Stack, StackDivider, Text, useDisclosure } from "@chakra-ui/react";
import { DefaultButton } from "../../sistema/components/DefaultButton"
import { ListHeaderSecondary } from "../../sistema/components/ListHeaderSecondary";
import { Formik, Form } from "formik";
import validateForm from "../../utils/validateForm";
import { useRef, useState } from 'react'
import { crmActions } from "../reducer";
import { useDispatch, useSelector } from "react-redux";
import { apiCall } from "../../utils/apiCall";
import { RootState } from "../../app/mainReducer";
import InputSelect from "../../sistema/components/InputSelect";
import InputField from "../../sistema/components/InputField";
import { ContatoRealizado } from "../types/contato_realizado";
import { arrayFormaContato } from "../arrays/forma_contato";
import { arrayMotivoNaoCompra } from "../arrays/motivo_nao_compra";
import { novoContatoRealizado } from "../data/contato_realizado";
import { mensagemErro } from "../../utils/toasts";
import { formatError } from "../../utils/formatError";
import { formatData } from "../../utils/data";
import { ButtonCardActions } from "../../cadastros/components/ButtonCardActions";

export const ContatosButton = ({ pessoa_id }: { pessoa_id: number | string }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const [contatosRealizados, setContatosRealizados] = useState<ContatoRealizado[]>([]);
  const [currentItem, setCurrentItem] = useState<ContatoRealizado | null>(null);
  const [showForm, setShowForm] = useState<boolean>(false);
  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile)

  const onBuscaDados = async () => {
    try {
      dispatch(crmActions.isLoading(true))

      const { data } = await apiCall({
        url: `contatos-realizados/${pessoa_id}`, method: 'GET'
      });

      setContatosRealizados(data);

      dispatch(crmActions.isLoading(false))
    } catch (err: any) {
      dispatch(crmActions.isLoading(false))
      console.log(err);
    }
  }

  const getLarguraPagina = (partes: number) => {
    if (isMobile) return '100%';

    return `${100 / partes}%`;
  }

  const arrayOpcoes = [
    {
      name: showForm ? 'Fechar' : 'Adicionar',
      manualmente: true,
    },
    {
      name: 'Ligou / Não atendeu',
      forma_contato: 'Telefone',
      motivo_nao_compra: 'Não Atendeu',
    },
    {
      name: 'Ligou / Não estava',
      forma_contato: 'Telefone',
      motivo_nao_compra: 'Comprador Indisponível',
    },
    {
      name: 'Ligou / Ligar novamente',
      forma_contato: 'Telefone',
      motivo_nao_compra: 'Não Informado',
    },
    {
      name: 'Ligou / Comprou',
      forma_contato: 'Telefone',
      motivo_nao_compra: 'Comprou',
    },
  ]

  const onSaveContatoRealizado = async (values: ContatoRealizado) => {
    try {
      dispatch(crmActions.isLoading(true))

      await apiCall({
        url: `contatos`, method: 'POST', data: {
          ...values,
          changed: true,
          pessoa_id,
        }
      });

      onBuscaDados();
      setShowForm(false);

      dispatch(crmActions.isLoading(false))
    } catch (err: any) {
      mensagemErro(formatError(err))
      dispatch(crmActions.isLoading(false))
      console.log(err);
    }
  }

  type IContatoAutomatico = typeof arrayOpcoes[0];

  const onClickAdicionarContato = (contato: IContatoAutomatico) => {
    if (contato?.manualmente) {
      if (showForm) {
        setShowForm(false)
        return;
      }
      setCurrentItem(novoContatoRealizado());
      setShowForm(true);
      return;
    }

    const novoContato = novoContatoRealizado();
    novoContato.forma_contato = contato?.forma_contato as string;
    novoContato.motivo_nao_compra = contato?.motivo_nao_compra as string;
    novoContato.observacoes = contato?.motivo_nao_compra as string;
    novoContato.pessoa_id = pessoa_id;

    onSaveContatoRealizado(novoContato);
  }

  const meuDivRef = useRef<any>(null)
  const onScrollToTop = () => {
    meuDivRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  const onDeleteItem = async (values: ContatoRealizado) => {
    if (!window.confirm('Deseja realmente excluir o contato realizado?')) {
      return;
    }

    try {
      dispatch(crmActions.isLoading(true))

      await apiCall({
        url: `contatos/${values.id}`, method: 'DELETE', data: {
          ...values,
          changed: true,
        }
      });

      onBuscaDados();
      setShowForm(false);

      dispatch(crmActions.isLoading(false))
    } catch (err: any) {
      mensagemErro(formatError(err))
      dispatch(crmActions.isLoading(false))
      console.log(err);
    }
  }

  return (
    <>
      {pessoa_id && <DefaultButton onClick={() => { onOpen(); onBuscaDados() }} borderRadius={"2px"} m={"2px"} size={"sm"}>
        Contatos
      </DefaultButton>}

      <Modal autoFocus={false} returnFocusOnClose={false} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"3xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ListHeaderSecondary label={'Contatos'}>
              <DefaultButton onClick={onClose} ml={2}>Voltar</DefaultButton>
            </ListHeaderSecondary>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody mb={2}>
            <Flex ref={meuDivRef} width={"full"} wrap={"wrap"}>
              {arrayOpcoes.map((i, index) => (
                <DefaultButton onClick={() => onClickAdicionarContato(i)} key={`${index}`} borderRadius={"2px"} m={"2px"} size={"sm"}>
                  {i.name}
                </DefaultButton>
              ))}
            </Flex>

            {currentItem && showForm && <Formik
              initialValues={currentItem}
              enableReinitialize
              onSubmit={async (values, { setErrors }) => {
                const validation = validateForm({
                  data: 'required',
                  forma_contato: 'required',
                  motivo_nao_compra: 'required',
                }, values);

                if (Object.keys(validation).length > 0) {
                  setErrors(validation)
                  return;
                }

                onSaveContatoRealizado(values);
              }}
            >
              {({ values }) => (
                <Form id="modal-contatos-button" >
                  <Flex width="full" wrap={"wrap"}>
                    <InputField type="datetime-local" label="Data / Hora" width={"full"} name="data" />
                    <InputSelect label="Forma de contato" name="forma_contato" width={getLarguraPagina(2)}>
                      {arrayFormaContato().map((i) => (
                        <option key={i.name} value={i.name}>{i.name}</option>
                      ))}
                    </InputSelect>
                    <InputSelect label="Motivo não compra" name="motivo_nao_compra" width={getLarguraPagina(2)}>
                      {arrayMotivoNaoCompra().map((i) => (
                        <option key={i.name} value={i.name}>{i.name}</option>
                      ))}
                    </InputSelect>
                    <InputField label="Observações" name="observacoes" width={"100%"} textarea={true} rows={2} />

                    <DefaultButton mt={1} type="submit" width={"full"}>
                      Salvar Contato Realizado
                    </DefaultButton>
                  </Flex>

                </Form>)}

            </Formik>}

            {contatosRealizados.length === 0 && <Heading size={"md"}>
              Nenhum encontrado
            </Heading>}

            <Grid mt={4} templateColumns={isMobile ? 'repeat(1, 1fr)' : 'repeat(3, 1fr)'} gap={2}>
              {(contatosRealizados ?? []).map((i, index) => (
                <Card bg={"gray.100"} key={`${index}`}>
                  <CardHeader>
                    <Heading size='sm'>
                      {formatData(i.data)}
                    </Heading>
                    <Text size=''>
                      {i.criador}
                    </Text>
                  </CardHeader>

                  <CardBody>
                    <Stack divider={<StackDivider />} spacing='4'>
                      <Box>
                        <Heading size='xs' textTransform='uppercase'>
                          Forma de Contato

                          <ButtonCardActions onEdit={() => { setCurrentItem(i); setShowForm(true); onScrollToTop() }} onDelete={() => { onDeleteItem(i) }} />
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                          {i.forma_contato}
                        </Text>
                      </Box>
                      <Box>
                        <Heading size='xs' textTransform='uppercase'>
                          Motivo Não Compra
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                          {i.motivo_nao_compra}
                        </Text>
                      </Box>
                      <Box>
                        <Heading size='xs' textTransform='uppercase'>
                          Observações
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                          {i.observacoes}
                        </Text>
                      </Box>
                    </Stack>
                  </CardBody>
                </Card>
              ))}

            </Grid>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}