import { useDispatch, useSelector } from "react-redux";
import InputSelect from "./InputSelect"
import { useEffect, InputHTMLAttributes } from "react";
import { RootState } from "../../app/mainReducer";
import { sistemaActions } from "../recucer";
import { Form, Formik, useField } from "formik";
import { apiCall } from "../../utils/apiCall";
import { novaLista } from "../../cadastros/data/lista";
import { useState } from "react";
import { Lista } from "../../cadastros/types/lista";
import { Box, Flex, Modal, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { Filhos } from "./Filhos";
import { novaListaItem } from "../../cadastros/data/lista_item";
import InputField from "./InputField";
import InputCheck from "./InputCheck";
import Loader from "./Loader";

export type InputSelectProps = InputHTMLAttributes<HTMLSelectElement> & {
  name: string;
  label: string;
  bgNone?: boolean;
  onChangeVal?: (val: string) => void;
  arrayName: string;
};

export const InputSelectArray: React.FC<InputSelectProps> = ({
  arrayName,
  ...props
}) => {
  const itens: { label: string; value: string | number }[] = useSelector((state: RootState) => (state.sistema?.listas ?? {})[arrayName] ?? []);
  const dispatch = useDispatch();
  const [field, { error, value }, { setValue }] = useField(props);

  useEffect(() => {
    dispatch(sistemaActions.getListaRequest(arrayName));
  }, [dispatch, arrayName]);

  const [lista, setLista] = useState<Lista | null>(null)
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoadingLocal, setIsLoadingLocal] = useState(false);

  const onAdicionarMaisOpcao = async () => {
    setIsLoadingLocal(true)
    const { data } = await apiCall({ url: `listas-nome/${arrayName}`, method: 'GET' });

    if (!data) {
      setLista({
        ...novaLista(),
        codigo: arrayName,
        descricao: arrayName,
      })
      setIsLoadingLocal(false)
      onOpen();
      return;
    }

    setLista(data);
    setIsLoadingLocal(false)
    onOpen();
  }

  const onSaveAdicionarMaisOpcao = async (values: Lista) => {
    setIsLoadingLocal(true)
    const { data } = await apiCall({ url: `listas?include=itens`, method: 'POST', data: values });

    dispatch(sistemaActions.getListaRequest(arrayName));

    setLista(data);
    setIsLoadingLocal(false)
  }

  const onChangeSelect = (valor: string) => {
    if (valor === 'add_option') {
      setValue(value, false);
      onAdicionarMaisOpcao();
      return;
    }

    setValue(valor, false)
  }

  return (
    <Box width={props.width}>
      <Loader isLoading={isLoadingLocal} />

      <InputSelect noPlaceholer={true} onChangeSetValue={(evt) => onChangeSelect(evt)} {...props} width={"100%"}>
        {value === 0 && <option selected disabled={itens.length > 0} value="0">Selecione...</option>}
        {value !== 0 && <option selected disabled={itens.length > 0} value="">Selecione...</option>}
        {itens.map((i, index) => (
          <option value={i.value} key={index}>
            {i.label}
          </option>
        ))}
        <option value="add_option" style={{ backgroundColor: '#a3a3a3', cursor: 'pointer', color: 'white' }}>Adicionar mais uma opção</option>
      </InputSelect>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"sm"}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalFooter>

            {lista && <Formik
              enableReinitialize
              initialValues={lista}
              onSubmit={(val, { setErrors }) => {
                onSaveAdicionarMaisOpcao({
                  ...val,
                  changed: true,
                })
              }}
            >
              {({ values, submitForm }) => (
                <Form>
                  <Flex width="full" wrap="wrap">
                    <Filhos
                      name="itens"
                      tituloForm="Itens"
                      editTituloForm="Item"
                      minH="0"
                      novoItemObject={novaListaItem(values?.itens?.length + 1)}
                      validation={{ ordem: 'required', descricao: 'required' }}
                      headers={[
                        {
                          label: "Descrição",
                          wrapped: false,
                          render: (item) => item?.descricao,
                        },
                        {
                          label: "Ativo?",
                          wrapped: false,
                          render: (item) => item?.ativo == 1 ? 'Sim' : 'Não',
                        },
                      ]}
                      hasMenuActions={false}
                      afterSave={submitForm}
                      form={(values) =>
                        <Flex pb={"10px"} width={"full"} wrap={"wrap"}>
                          <InputField name="descricao" label="Descrição" width={'100%'} />
                          <InputCheck name="ativo" label="Ativo?" width={'100%'} />
                        </Flex>
                      }
                    />
                  </Flex>
                </Form>
              )}
            </Formik>}

          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}