import { PedidoItem } from "../../entradaesaida/types/pedido_item";
import { getDecimalPlacesOfSystem } from "../../sistema/utils/getDecimalPlacesOfSystem";

export const getTotalCartValue = (items: PedidoItem[]) => {
  return items.reduce((previousValue, currrentValue) => {
    return (
      previousValue + currrentValue.quantidade * currrentValue.valor_unitario
    );
  }, 0);
};

export const getTotalDiscountCartValue = (items: PedidoItem[]) => {
  return parseFloat(
    items
      .reduce((previousValue, currrentValue) => {
        return previousValue + currrentValue.valor_desconto;
      }, 0)
      .toFixed(getDecimalPlacesOfSystem())
  );
};

export const getTotalFinalCartValue = (items: PedidoItem[]) => {
  return parseFloat(
    items
      .reduce((previousValue, currrentValue) => {
        return previousValue + currrentValue.valor_total;
      }, 0)
      .toFixed(getDecimalPlacesOfSystem())
  );
};
