import { useDispatch, useSelector } from "react-redux";
import Layout from "../../sistema/components/Layout";
import Loader from "../../sistema/components/Loader";
import { RootState } from "../../app/mainReducer";
import { Form, Formik } from "formik";
import { Items } from "../components/cart/Items";
import { Header } from "../components/cart/Header";
import { Actions } from "../components/cart/Actions";
import { pdvActions } from "../reducer";
import { useEffect, useState } from "react";
import { getTotalFinalCartValue } from "../utils/cartUtils";
import { Pedido } from "../../entradaesaida/types/pedido";
import { novoPedido } from "../../entradaesaida/data/pedido";
import { OrderSuccess } from "../components/cart/OrderSuccess";
import { mensagemErro } from "../../utils/toasts";

export const Cart = () => {
  const isLoading = useSelector(
    (state: RootState) => state.pdv.isLoading ?? false
  );
  const order = useSelector((state: RootState) => state.pdv.order);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(pdvActions.getSellersRequest());
    dispatch(pdvActions.getLocationsStockRequest());
  }, [dispatch]);

  const [orderSuccess, setOrderSuccess] = useState<Pedido | null>(null);

  useEffect(() => {
    if (order?.id) {
      setOrderSuccess(order);
      dispatch(pdvActions.updateOrder(novoPedido()));
    }
  }, [order]);

  return (
    <Layout padding={0}>
      <Formik
        enableReinitialize
        initialValues={order}
        onSubmit={(values, { setErrors }) => {
          const orderToDispatch: Pedido = {
            ...values,
            valor_total: getTotalFinalCartValue(values.itens ?? []) as any,
            changed: true,
            financeiros: (values?.financeiros ?? []).map((financeiro) => ({
              ...financeiro,
              pessoa_id: values.pessoa_id,
              seller_id: values.seller_id,
            })),
          };

          if ((orderToDispatch?.itens ?? []).length === 0) {
            mensagemErro("Coloque ao menos 1 produto no carrinho para salvar.");
            return;
          }

          console.log(orderToDispatch);

          dispatch(pdvActions.updateOrder(orderToDispatch));
          dispatch(pdvActions.saveOrderRequest(orderToDispatch));
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <OrderSuccess order={orderSuccess} />

            <div className="w-full min-h-[200vh] sm:min-h-[100vh] p-3 bg-gray-200">
              <Loader isLoading={isLoading} />

              <div className="w-full flex flex-wrap min-h-[25vh] max-h-[25vh] mb-4 sm:mb-4">
                <div className="w-full mb-1">
                  <Header />
                </div>
                <div className="w-full flex flex-wrap md:w-2/3 mb-4 sm:mb-4">
                  <Items />
                </div>
                <div className="w-full flex flex-wrap md:w-1/3 mb-4 sm:mb-4">
                  <Actions />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Layout>
  );
};
