import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  List,
  ListItem,
  Spinner,
} from "@chakra-ui/react";
import { useField } from "formik";
import { useState, useEffect, ReactNode } from "react";
import { apiCall } from "../../utils/apiCall";
import { FiX } from "react-icons/fi";
import { useFocus } from "../../hooks/useFocus";

interface IProps {
  label: string;
  name: string;
  visualizacao: (item: any) => string | ReactNode;
  endpoint: string;
  width?: string;
  placeholder?: string;
  includes?: string;
  onSelectItem?: (item: any) => void;
  onSelectItemOnLoad?: (item: any) => void;
  setFocus?: boolean;
}

export const BobjetoPadrao = ({
  label,
  name,
  endpoint,
  width = `100%`,
  placeholder,
  visualizacao,
  onSelectItem,
  onSelectItemOnLoad,
  includes,
  setFocus = false,
}: IProps) => {
  const [, { error, touched, value }, { setValue }] = useField({ name });

  const [searchResults, setSearchResults] = useState<any>({});
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [showList, setShowList] = useState<boolean>(false);

  const [inputRef, setInputFocus] = useFocus()

  useEffect(() => {
    if (setFocus) {
      (setInputFocus as any)();
    }
  }, [setFocus, setInputFocus])

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const { data } = await apiCall({
          url: `${endpoint}?termo=${searchTerm}`,
          method: "GET",
        });
        setIsLoading(false);
        setShowList(true);
        setSearchResults(data);
      } catch (error) {
        console.error("Erro ao buscar dados da API", error);
        setIsLoading(false);
      }
    };

    const debounceTimer = setTimeout(() => {
      if (searchTerm.trim() !== "") {
        fetchData();
      } else {
        setShowList(false);
        setSearchResults([]);
      }
    }, 200);

    return () => clearTimeout(debounceTimer);
  }, [searchTerm, endpoint]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const { data } = await apiCall({
          url: `${endpoint}/${value}?include=${includes ?? ""}`,
          method: "GET",
        });
        setIsLoading(false);
        setSelectedItem(data);
        typeof onSelectItemOnLoad === "function" && onSelectItemOnLoad(data);
      } catch (error) {
        console.error("Erro ao buscar dados da API", error);
        setIsLoading(false);
      }
    };

    const debounceTimer = setTimeout(() => {
      if (!value) {
        setShowList(false);
        setSearchResults([]);
        setSelectedItem(null);
        typeof onSelectItem === "function" && onSelectItem(null);
      } else {
        fetchData();
      }
    }, 100);

    return () => clearTimeout(debounceTimer);
  }, [value]);

  const handleSelectItem = (item: any) => {
    console.log(item);
    setValue(item.id as any);
    setSelectedItem(item);
    typeof onSelectItem === "function" && onSelectItem(item);
  };

  const removeItem = () => {
    setValue("");
    setSelectedItem(null);
    setShowList(false);
    setSearchResults([]);
    typeof onSelectItem === "function" && onSelectItem(null);
  };

  return (
    <Flex
      width={width}
      position={"relative"}
      onFocusCapture={() => setShowList(true)}
      onBlurCapture={() => setTimeout(() => setShowList(false), 150)}
    >
      {!value && !selectedItem ? (
        <FormControl
          width={"100%"}
          isInvalid={touched && error ? true : false}
          p={1}
        >
          {label && (
            <FormLabel mb={0} mt={2}>
              {label}
            </FormLabel>
          )}
          <Input
            position={"relative"}
            bg={"white"}
            color={"black"}
            placeholder={placeholder ? placeholder : label}
            isInvalid={touched && error ? true : false}
            borderRadius={2}
            onChange={(e) => setSearchTerm(e.target.value)}
            ref={inputRef}
          />
          {isLoading && (
            <Flex
              zIndex={99}
              position={"absolute"}
              right={"10px"}
              bottom={label ? "12px" : "12px"}
            >
              <Spinner />
            </Flex>
          )}
          {error && <FormErrorMessage mt={1}>{error}</FormErrorMessage>}
        </FormControl>
      ) : (
        <Flex direction={"column"} width={"full"} p={1}>
          {label && (
            <FormLabel mb={0} mt={2}>
              {label}
            </FormLabel>
          )}
          <Flex
            position={"relative"}
            alignItems={"center"}
            p={2}
            width={"full"}
            borderRadius={2}
            bg={"gray.300"}
            color={"black"}
            height={"40px"}
          >
            {visualizacao(selectedItem)}

            <Flex
              onClick={removeItem}
              _hover={{ backgroundColor: "#d3d3d3" }}
              cursor={"pointer"}
              p={1}
              borderRadius={"5px"}
              position={"absolute"}
              bottom={isLoading ? "4px" : "8px"}
              right={"5px"}
            >
              {isLoading ? <Spinner /> : <FiX />}
            </Flex>
          </Flex>
        </Flex>
      )}
      {!isLoading &&
        !value &&
        (searchResults?.data ?? []).length > 0 &&
        showList && (
          <List
            maxH={"50vh"}
            overflowY={"auto"}
            mt={2}
            position={"absolute"}
            p={2}
            bg={"white"}
            borderRadius={"5px"}
            width={"100%"}
            top={label ? "70px" : "40px"}
            zIndex={100}
          >
            {(searchResults?.data ?? []).map((result: any) => (
              <ListItem
                key={result.id}
                onClick={() => handleSelectItem(result)}
                cursor="pointer"
                bg={"gray.100"}
                p={2}
                _hover={{ bg: "gray.200" }}
                borderRadius={"5px"}
                mb={1}
              >
                {visualizacao(result)}
              </ListItem>
            ))}
          </List>
        )}
    </Flex>
  );
};
