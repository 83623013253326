import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useIsAuth } from "../../hooks/useIsAuth";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Layout from "../../sistema/components/Layout";
import { ListHeader } from "../../sistema/components/ListHeader";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import { PessoaForm } from "../components/PessoaForm";
import { cadastroActions } from "../reducer";

export const PessoaEdit = () => {
  useIsAuth();

  const { id, tipo } = useParams();

  const [tipoCadastro, setTipoCadastro] = useState("Cliente");

  useEffect(() => {
    if (tipo == "clientes") {
      setTipoCadastro("Cliente");
      return;
    }
    setTipoCadastro("Fornecedor");
  }, [tipo, setTipoCadastro]);

  const pessoa = useSelector(
    (state: RootState) => state.cadastro.pessoa || null
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!id) return;

    dispatch(cadastroActions.pessoaRequest(id));
  }, [dispatch, id]);

  return (
    <Layout>
      <ListHeader
        label={`${tipoCadastro} ${
          pessoa?.codigo && pessoa?.codigo > 0 ? pessoa?.codigo : ""
        }`}
      >
        <DefaultButton
          ml={4}
          to={
            tipo == "clientes"
              ? "/relacao-pessoas/clientes"
              : "/relacao-pessoas/fornecedores"
          }
        >
          {" "}
          Voltar{" "}
        </DefaultButton>
      </ListHeader>

      <PessoaForm pessoa={pessoa} tipo={tipo ?? ''} />
    </Layout>
  );
};
